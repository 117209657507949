import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { t } from 'i18next';
import {
  Form,
  Checkbox,
  Text, Select, SelectItem, BlockNotification
} from '@wfp/react';
import { DeliveryState, IDelivery, ISlot } from '../../types/delivery';
import { useGetBaskets } from '../../hooks/basket';
import { useGetCycle } from '../../hooks/cycle';
import { LoadingCenter } from '../commons/loading-center';
import { dateTimeToString } from '../../utils/format';
import { IServerError } from '../../types/commons';

export interface IDeliveryErrorInfo extends IServerError {
  scheduledFrom?: string[];
  scheduledTo?: string[];
  baskets?: string[];
}

interface IDeliveryFormProps {
  item: IDelivery;
  onChange: (value: IDelivery) => void;
  error?: IDeliveryErrorInfo;
}

const slots: ISlot[] = [
  { id: 0, name: '08:00 - 11:00', scheduleFrom: 8, scheduleTo: 11 },
  { id: 1, name: '11:00 - 14:00', scheduleFrom: 11, scheduleTo: 14 },
  { id: 2, name: '14:00 - 16:00', scheduleFrom: 14, scheduleTo: 16 },
];

const findSlot = (scheduledFrom: string): number => {
  const date = new Date(scheduledFrom);
  const idx =  slots.findIndex((item) => item.scheduleFrom === date.getHours());
  return idx === -1 ? 0 : idx
}

export function DeliveryForm(props: IDeliveryFormProps) {
  const { data: basketPage, isLoading: isLoadingBasketPage, isError: isErrorBasketPage } = useGetBaskets({ pageNumber: 1 });
  const {
    data: cycle,
  } = useGetCycle(props.item.cycle);

  const [baskets, setBaskets] = useState<number[]>(props.item.baskets);

  const [date, setDate] = useState<Date | null>(
    new Date(props.item.scheduledFrom)
  );
  const [slot, setSlot] = useState<number>(findSlot(props.item.scheduledFrom));

  function dateSlotToString(date: Date, hour: number): string {
    date.setHours(hour);
    return dateTimeToString(date);
  }
  function dateFromSlot(date: Date, slot: number): string {
    return dateSlotToString(date, slots[slot].scheduleFrom);
  }

  function dateToSlot(date: Date, slot: number): string {
    return dateSlotToString(date, slots[slot].scheduleTo);
  }

  const handleSelectDate = (value: Date | null) => {
    if (!value)  {
      return;
    }
    props.onChange({ ...props.item, scheduledFrom: dateFromSlot(value, slot), scheduledTo: dateToSlot(value, slot) });
    setDate(value);
  };

  const handleSelectSlot = (value: any) => {
    if (!date) {
      return;
    }
    props.onChange({ ...props.item, scheduledFrom: dateFromSlot(date, value), scheduledTo: dateToSlot(date, value) });
    setSlot(value);
  }

  const onChangeBasket = (id: number) => {
    const value = baskets.includes(id) ? baskets.filter(item => item !== id) : [...baskets, id];
    setBaskets(value);
    props.onChange({...props.item, baskets: value});
  }

  return (
    <Form>
      {props.error?.errors && (
        <BlockNotification
          kind="error"
          title={t("Error")}
          subtitle={t(props.error?.errors?.toString())}
        />
      )}
      <div className="space-y-5">
        {props.item.state === DeliveryState.BOOKED && (
          <>
            <div className="wfp--form-item">
              <div className='wfp--label'>{t('delivery-date-select')}</div>
              <div className="wfp--input-wrapper wfp--input">
                <ReactDatePicker
                  name="date"
                  className="wfp--text-input"
                  inline
                  showIcon={false}
                  selected={date}
                  onChange={handleSelectDate}
                  minDate={ !!cycle ? new Date(cycle?.dateFrom) : undefined}
                  maxDate={ !!cycle ? new Date(cycle?.dateTo) : undefined}
                />
              </div>
            </div>
            <Select
              labelText={t("delivery-slot-select")!}
              name="slot"
              onChange={(e) => handleSelectSlot(e.target.value)}
              value={slot}
            >
              <SelectItem disabled hidden value="placeholder-item" text="Choose an option" />
              {slots.map((s, idx) => (<SelectItem key={idx} value={s.id} text={s.name} />))}
            </Select>
          </>
        )}
        {props.error?.scheduledFrom && (
          <p className="text-red-500">
            {t(props.error?.scheduledFrom?.toString() || "")}
          </p>
        )}
        {props.error?.scheduledTo && (
          <p className="text-red-500">
            {t(props.error?.scheduledTo?.toString() || "")}
          </p>
        )}
        {isLoadingBasketPage && <LoadingCenter />}
        {isErrorBasketPage && <div>{t("Error")}</div>}
        {!!basketPage && (
          <div className="wfp--form-item">
            <Text className="wfp--label">{t("basket-list")}</Text>
            {props.error?.baskets && (
              <p className="text-red-500">
                {t(props.error?.baskets?.toString() || "")}
              </p>
            )}
            <div className="overflow-y-scroll max-h-60 w-full">
              {basketPage.results.map((b, idx) => (
                <div className="" key={idx}>
                  <Checkbox
                    name={b.name}
                    value={b.id}
                    onChange={(e) => onChangeBasket(Number(e.target.value))}
                    labelText={b.name}
                    checked={baskets?.includes(b.id) }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Form>
  );
}
