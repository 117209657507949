import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@wfp/react";
import { IDelivery } from "../../types/delivery";
import { bulkCreateCycle } from "../../services/cycle";
import DeliveryBulkForm from "./delivery-bulk-form";
import { useInvalidateCycle } from "../../hooks/cycle";
import { useInvalidateCycleDeliveries } from '../../hooks/delivery';
import { IServerError } from '../../types/commons';
import { IBeneficiary, IBeneficiaryGroup } from '../../types/beneficiary';
import { useNotification } from '../../hooks/notification';

interface Props {
  item?: IDelivery;
  className?: string;
  cycleId: number;
}

export interface IBulkError extends IServerError{
  baskets?: string[];
  beneficiaries?: string[];
  beneficiary?: string[];
  group?: string[];
}
export default function DeliveryAddEdit(props: Props) {
  const { t } = useTranslation();
  const [baskets, setBaskets] = useState<number[]>();
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>();
  const [groups, setGroups] = useState<IBeneficiaryGroup[]>();
  const [allBeneficiaries, setAllBeneficiaries] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState(false);
  const [bulkError, setBulkError] = useState<IBulkError>();
  const { mutate: invalidateCycle } = useInvalidateCycle(props.cycleId);
  const { mutate: updateCycleDeliveries} = useInvalidateCycleDeliveries(props.cycleId);
  const { show } = useNotification();

  const handleAddDeliveries = () => {
    setOpenModal(true);
    setBulkError(undefined);
    setBaskets(undefined);
    setGroups(undefined);
    setAllBeneficiaries(true);
  };

  const handleAddDeliveriesConfirm = () => {
    let error = {...bulkError, errors: undefined};
    let hasError = false;
    if (!baskets || baskets.length === 0) {
      error = {...error, baskets: ["delivery-add-error-baskets"]};
      hasError = true;
    } else {
      error = {...error, baskets: undefined};
    }
    if (!allBeneficiaries && !groups && !beneficiaries) {
      error = {
        ...error,
        group: ["delivery-add-error-group"],
        beneficiary: ["delivery-add-error-beneficiary"],
        beneficiaries: ["delivery-add-error-beneficiaries"],
      };
      hasError = true;
    } else {
      error = {
        ...error,
        group: undefined,
        beneficiary: undefined,
        beneficiaries: undefined,
      };
    }
    if (hasError) {
      setBulkError(error);
    } else {
      setBulkError(undefined);
      bulkCreateCycle(props.cycleId, baskets!, allBeneficiaries, (groups || []).map(i => i.id), (beneficiaries || []).map(i => i.id) )
        .then((res: any) => {
          show(t('delivery-add-bulk-title'), t('delivery-add-bulk-results', {baskets: res.baskets, beneficiaries: res.beneficiaries}), {kind: 'success', timeout: 5000})
          invalidateCycle();
          updateCycleDeliveries();
          setOpenModal(false);
          setAllBeneficiaries(true);
          setBaskets(undefined);
          setGroups(undefined);
          setBeneficiaries(undefined);
          setBulkError(undefined);
        })
        .catch((e) => setBulkError(e));
    }
  };

  const handleAddDeliveriesCancel = () => {
    setOpenModal(false);
    setAllBeneficiaries(true);
    setBaskets(undefined);
    setGroups(undefined);
    setBeneficiaries(undefined);
    setBulkError(undefined);
  };

  const handleChangeBaskets = (baskets: number[]) => {
    setBaskets(baskets);
    setBulkError({...bulkError, baskets: undefined});
  }
  const handleChangeAllBeneficiaries = (value: boolean) => {
    setAllBeneficiaries(value);
    setBulkError({...bulkError, beneficiaries: undefined, group: undefined, beneficiary: undefined});
  }

  const handleChangeGroup = (item: IBeneficiaryGroup | undefined) => {
    setGroups(!!item ? [item] : undefined);
    setBulkError({...bulkError, beneficiaries: undefined, group: undefined, beneficiary: undefined});
  }

  const handleChangeBeneficiary = (item: IBeneficiary | undefined) => {
    setBeneficiaries(!!item ? [item] : undefined);
    setBulkError({...bulkError, beneficiaries: undefined, group: undefined, beneficiary: undefined});
  }

  return (
    <div className={props.className}>
      <Button kind="secondary" onClick={handleAddDeliveries} className="">
        {!!props.item ? t("delivery-edit") : t("delivery-add")}
      </Button>
      <Modal
        open={openModal}
        onRequestClose={handleAddDeliveriesCancel}
        onRequestSubmit={handleAddDeliveriesConfirm}
        modalHeading={t("delivery-add-form-title")}
        modalLabel={!!props.item ? t("delivery-edit") : t("delivery-add")}
        primaryButtonText={!!props.item ? t('edit') : t("add")}
        secondaryButtonText={t("cancel")}
        width="full"
        lazyLoad
        children={
          <DeliveryBulkForm
            onChangeBeneficiary={handleChangeBeneficiary}
            onChangeBaskets={handleChangeBaskets}
            onChangeGroup={handleChangeGroup}
            onChangeAllBeneficiary={handleChangeAllBeneficiaries}
            allBeneficiaries={allBeneficiaries}
            baskets={baskets}
            error={bulkError}
            beneficiary={!!beneficiaries ? beneficiaries[0] : undefined}
            group={!!groups ? groups[0] : undefined}
          />
        }
      />
    </div>
  );
}
