import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Role } from "../types/user";
import { useLoggedUser } from "../hooks/user";
import { LoadingCenter } from "../components/commons/loading-center";
import { Link } from "@wfp/react";
import { useTranslation } from "react-i18next";
import { Logo } from "../components/commons/logo";

export const Home = () => {
  const navigate = useNavigate();
  const user = useLoggedUser();
  const { t } = useTranslation();

  useEffect(() => {
    if (!!user && !!navigate) {
      if ([Role.Beneficiary].includes(user.role)) {
        navigate(`/mobile`);
      }
    }
  }, [user, navigate]);

  if (!user) {
    return <LoadingCenter />;
  }

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col">
        <Logo />
        {[Role.WFPManager, Role.WFPStaff].includes(user.role) && <div className="wfp--home--menu text-center">
          <div className={user.role === Role.WFPManager ? "grid grid-cols-5 gap-x-8" : "grid grid-cols-4 gap-x-12"}>
          <Link href="/beneficiary" linkSolid visited={true}>
            {t("Beneficiaries")}
          </Link>
          <Link href="/beneficiary-group" linkSolid visited={true}>
            {t("beneficiary-group-list")}
          </Link>
          <Link href="/basket" linkSolid visited={true}>
            {t("basket-list")}
          </Link>
          <Link href="/cycle" linkSolid visited={true}>
            {t("Cycles")}
          </Link>
          {user.role === Role.WFPManager && <Link href="/user" linkSolid visited={true}>
            {t("user-list")}
          </Link>}
          </div>
        </div>
        }
      </div>
    </div>
  );
};
