import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbHome,
  BreadcrumbItem,
  Button,
  Modal,
  Text,
} from "@wfp/react";
import { Delete } from "@wfp/icons-react";
import { IServerError } from "../../types/commons";
import { disableUser } from "../../services/user";
import { useGetUser } from "../../hooks/user";
import { LoadingCenter } from "../commons/loading-center";

function UserDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [serverError, setServerError] = useState<IServerError>();

  const {
    data: user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useGetUser(Number(id));

  const handleDeleteUserCancel = () => {
    setOpenModal(false);
  };

  const handleDeleteUserConfirm = () => {
    if (!!user) {
      disableUser(user?.pk)
        .then(() => {
          setOpenModal(false);
          setServerError(undefined);
          navigate(`/user/`);
        })
        .catch((e) => setServerError(e as IServerError));
    }
  };

  const handleUserDelete = () => {
    setServerError(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href="/user">{t("user-list")}</BreadcrumbItem>
        <BreadcrumbItem disableLink>{user?.username}</BreadcrumbItem>
      </Breadcrumb>
      {isLoadingUser && <LoadingCenter />}
      {isErrorUser && <div>Error...</div>}
      {user && (
        <div className="mt-8">
          <div className="text-justify">
            <div className="flex justify-between">
              <Text kind="story-title" className="text-2xl">
                {t("user-view")}
              </Text>
              <div className="flex gap-2">
                <Button
                  icon={Delete}
                  kind="danger"
                  onClick={handleUserDelete}
                >
                  {t("delete")}
                </Button>
              </div>
            </div>
            <div className="w-2/5">
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("Email")}: </span>
                <p>{user.username}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("Role")}: </span>
                <p>{user.role}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {openModal && (
        <Modal
          open={true}
          onRequestClose={handleDeleteUserCancel}
          onRequestSubmit={handleDeleteUserConfirm}
          modalHeading={t("user-delete")}
          primaryButtonText={t("Yes")}
          secondaryButtonText={t("No")}
          width="full"
        >
          <Text>{t("user-delete-confirm")}</Text>
          {serverError && (
            <Text className="text-red-500" kind="error">{serverError?.errors?.toString()}</Text>
          )}
        </Modal>
      )}
    </>
  );
}

export default UserDetail;
