import { useTranslation } from "react-i18next";
import { Form, Select, SelectItem, Text, TextInput } from '@wfp/react';
import { IUser } from "../../types/user";
import { IUserError } from '../../models/validate-user';

interface UserFormProps {
  item: IUser;
  error?: IUserError;
  onChange: (user: IUser) => void;
}

function UserForm(props: UserFormProps) {
  const { t } = useTranslation();

  return (
    <div>
      <Form>
        <TextInput
          invalid={!!props.error?.username}
          invalidText={t(props.error?.username?.toString() || '')}
          labelText={t("email")!}
          name="username"
          onChange={(e: any) => props.onChange({...props.item, username: e.target.value})}
          required
          type='text'
          value={props.item.username || ''}
        />
        <Select
          invalid={!!props.error?.role}
          invalidText={t(props.error?.role?.toString() || '')}
          labelText={t("role")!}
          name="role"
          onChange={(e: any) => props.onChange({...props.item, role: e.target.value})}
          helperText="Optional helperText"
          value={props.item.role || ''}
        >
          <SelectItem text={t('role-select')} value=""/>
          <SelectItem text={t('role-manager')} value="wfpmanager"/>
          <SelectItem text={t('role-staff')} value="wfpstaff"/>
        </Select>
        {props.error && (
            <Text className="text-red-500" kind="error">{props.error?.errors?.toString()}</Text>
        )}
      </Form>
    </div>
  );
}

export default UserForm;
