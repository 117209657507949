import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BlockNotification, Button, Modal, Text, useMediaQuery } from '@wfp/react';
import { KVPair, PaginationParams, IServerError } from '../../types/commons';
import { IUser } from "../../types/user";
import { IUserError, validateUser } from '../../models/validate-user';
import { saveUser, disableUser } from '../../services/user';
import { useGetUsers, useInvalidateUsers } from "../../hooks/user";
import { LoadingCenter } from '../commons/loading-center';
import UserTable from "./user-table";
import UserForm from './user-form';


function UserPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({ pageNumber: 1 });
  const [sorting, setSorting] = useState<string[] | undefined>(['id']);
  const [filters, setFilters] = useState<KVPair[] | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const { data: usersPage, isLoading, isError } = useGetUsers(paginationParams, filters, sorting);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [userError, setUserError] = useState<IUserError>();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [serverError, setServerError] = useState<IServerError>();
  const { mutate: updateUsers } = useInvalidateUsers(paginationParams, filters, sorting);

  const handleUserAdd = () => {
      if (!!usersPage) {
          setOpenModal(true);
          setUserError(undefined);
          setSelectedUser({} as IUser);
      }
  }

  const handleUserEdit = (user: IUser) => {
    setSelectedUser(user);
    setUserError(undefined);
    setOpenModal(true);
  }

  const handleUserSaveConfirm = () => {
      if (!!selectedUser) {
          validateUser(selectedUser)
              .then((user) =>
                  saveUser(user)
                      .then(res => {
                          setOpenModal(false);
                          setUserError(undefined);
                          setSelectedUser(res);
                          if (!selectedUser.pk) {
                              navigate(`/user/${res.pk}`);
                          } else {
                              updateUsers();
                          }
                      })
                      .catch(e => setUserError(e as IUserError)))
              .catch(e => setUserError(e));
      }
  }

  const handleUserSaveCancel = () => {
      setOpenModal(false);
      setUserError(undefined);
      setSelectedUser(undefined);
  }

  const handleUserDelete = (user: IUser) => {
    setSelectedUser(user);
    setConfirmDelete(true);
    setServerError(undefined);
    setOpenModal(true);
  }

  const handleUserDeleteConfirm = () => {
    if (!!selectedUser && confirmDelete) {
      disableUser(selectedUser.pk)
        .then(() => {
          setOpenModal(false);
          setConfirmDelete(false);
          setSelectedUser(undefined);
          setServerError(undefined);
          updateUsers();
      }).catch((e) => setServerError(e as IServerError));
    }
  };
  const handleUserDeleteCancel = () => {
      setOpenModal(false);
      setConfirmDelete(false);
      setServerError(undefined);
      setSelectedUser(undefined);
  }

  return (
    <>
      <div className="mt-10 ">
        <div className="flex justify-between">
          <Text kind="story-title" className="text-2xl text-left">
            {t("user-list")}
          </Text>
          <Button onClick={handleUserAdd}>{t("user-add")}</Button>
        </div>
        {isLoading && <LoadingCenter />}
        {isError && <div>Error...</div>}
        {!!usersPage && (
          <>
            {usersPage.count === 0 && <BlockNotification
              kind="info"
              title={t("NoUsers")}
              subtitle={t("NoUsersDescription")}
            />}
            {isSmallScreen ? (
              <>
                {usersPage.results.map((user) => (
                  <div className="m-5 wfp--card-box p-4" key={user.pk} onClick={() => navigate(`/user/${user.pk}`)}>
                    <div className="text-left m-4">
                      <div className="flex justify-between mb-3">
                        <span className="text-gray-500">{t("Email")}: </span>
                        <p>{user.username}</p>
                      </div>
                      <div className="flex justify-between mb-3">
                        <span className="text-gray-500">{t("Role")}: </span>
                        <p>{user.role}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="flex-col">
                  <UserTable page={usersPage}
                               onChangePagination={setPaginationParams}
                               onChangeSorting={setSorting}
                               onDeleteRow={handleUserDelete}
                               onEditRow={handleUserEdit}
                  />
              </div>
            )}
          </>
        )}
      </div>
      {openModal && !selectedUser?.pk && (
      <Modal
        open={true}
        onRequestClose={handleUserSaveCancel}
        onRequestSubmit={handleUserSaveConfirm}
        modalHeading={t("user-add")}
        primaryButtonText={t("add")}
        secondaryButtonText={t("cancel")}
      >
        <UserForm onChange={setSelectedUser} item={selectedUser!} error={userError} />
      </Modal>
      )}
      {openModal && !!selectedUser?.pk && !confirmDelete && (
      <Modal
        open={true}
        onRequestClose={handleUserSaveCancel}
        onRequestSubmit={handleUserSaveConfirm}
        modalHeading={t("user-edit")}
        primaryButtonText={t("save")}
        secondaryButtonText={t("cancel")}
      >
        <UserForm onChange={setSelectedUser} item={selectedUser!} error={userError} />
      </Modal>
      )}
      {openModal && !!selectedUser?.pk && !!confirmDelete && (
      <Modal
        open={true}
        onRequestClose={handleUserDeleteCancel}
        onRequestSubmit={handleUserDeleteConfirm}
        modalHeading={t("user-delete")}
        primaryButtonText={t("Yes")}
        secondaryButtonText={t("No")}
      >
          <Text>{t("user-delete-confirm")}</Text>
          {serverError && (
            <Text className="text-red-500" kind="error">{serverError?.errors?.toString()}</Text>
          )}
      </Modal>
      )}
    </>
  );
}

export default UserPage;
