import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import { DateRangePicker as WfpDatePicker, Form, TextInput } from "@wfp/react";

import { ICycle } from "../../types/cycle";
import { ICycleError } from "../../models/validate-cycle";
import { dateToString } from '../../utils/format';

interface Props {
  item: ICycle;
  error?: ICycleError;
  onChange: (cycle: ICycle, field: 'nameAr' | 'nameEn' | 'dateFrom' | 'dateTo') => void;
}
function CycleForm({ ...props }: Props) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(props.item.dateFrom ? new Date(props.item.dateFrom) : null);
  const [endDate, setEndDate] = useState<Date | null>(props.item.dateTo ? new Date(props.item.dateTo) : null);

  const handleSelectDate = (selectedDate: Date, dateType: "from" | "to") => {
    if (dateType === "from") {
      props.onChange({ ...props.item, dateFrom: dateToString(selectedDate)}, 'dateFrom');
      setStartDate(selectedDate);
    } else if (dateType === "to") {
      props.onChange({ ...props.item, dateTo: dateToString(selectedDate)}, 'dateTo');
      setEndDate(selectedDate);
    }
  };

  return (
    <Form>
      <div className="space-y-5">
        <WfpDatePicker
          labelText={t("selectDateRange")}
          datePicker={ReactDatePicker}
          // startDate={startDate}
          // endDate={endDate}
          fromProps={{
            selected: startDate,
            onSelect: (date: Date) => handleSelectDate(date, "from"),
            error: !!props.error?.dateFrom,
          }}
          toProps={{
            selected: endDate,
            onSelect: (date: Date) => handleSelectDate(date, "to"),
            disabled: !startDate,
            error: !!props.error?.dateTo,
          }}
        />
        {props.error?.dateFrom && (
          <p className="text-red-500">{t(props.error?.dateFrom?.toString() || "")}</p>
        )}
        {props.error?.dateTo && (
          <p className="text-red-500">{t(props.error?.dateTo?.toString() || "")}</p>
        )}
        <TextInput
          invalid={!!props.error?.nameAr}
          invalidText={t(props.error?.nameAr?.toString() || "")}
          labelText={t("nameAr")!}
          name="nameAr"
          onChange={(e: any) =>
            props.onChange({ ...props.item, nameAr: e.target.value }, 'nameAr')
          }
          required
          type="text"
          value={props.item.nameAr || ""}
          className="pb-5"
        />
        <TextInput
          invalid={!!props.error?.nameEn}
          invalidText={t(props.error?.nameEn?.toString() || "")}
          labelText={t("nameEn")!}
          name="nameEn"
          onChange={(e: any) =>
            props.onChange({ ...props.item, nameEn: e.target.value }, 'nameEn')
          }
          required
          type="text"
          value={props.item.nameEn || ""}
          className="pb-5"
        />
      </div>
    </Form>
  );
}

export default CycleForm;
