import { useTranslation } from "react-i18next";
import { Form, Text, TextInput } from '@wfp/react';

import { IBeneficiary, IBeneficiaryGroup } from '../../types/beneficiary';
import { IBeneficiaryError } from "../../models/validate-beneficiary";
import Toggle from "../commons/Toggle";
import { useState } from "react";
import { useGetBeneficiaryGroups } from '../../hooks/beneficiary-group';
import { getBeneficiaryGroups } from "../../services/beneficiary-group";
import { Typeahead } from '../commons/Typeahead';

interface Props {
  item: IBeneficiary;
  error?: IBeneficiaryError;
  onChange: (
    beneficiary: IBeneficiary,
    field: "email" | "idmId" | "address" | "isActive" | "group"
  ) => void;
}
function BeneficiaryForm({ ...props }: Props) {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(props.item.isActive || false);
  const ToggleActive = false;
  const { data: groupsPage } = useGetBeneficiaryGroups({pageNumber: 1, pageSize: 5});

  const onGroupChange = (e: {id: string, name: string} ) => {
      const group = !e || !e.id ? undefined : Number(e.id);
      const groupName = !e || !e.id ? undefined : e.name;
      props.onChange({...props.item, group, groupName }, "group")
  }

  return (
    <Form>
      <div className="space-y-5">
        {!props.item.id && (
            <TextInput
                invalid={!!props.error?.email}
                invalidText={t(props.error?.email?.toString() || "")}
                labelText={t("email")!}
                name="email"
                onChange={(e: any) =>
                    props.onChange({...props.item, email: e.target.value}, "email")
                }
                required
                type="email"
                value={props.item.email || ""}
                className="pb-5"
            />
        )}
        <TextInput
            invalid={!!props.error?.idmId}
            invalidText={t(props.error?.idmId?.toString() || "")}
            labelText={t("idmId")!}
            name="idmId"
            onChange={(e: any) =>
                props.onChange({...props.item, idmId: e.target.value}, "idmId")
            }
            required
            type="text"
            value={props.item.idmId || ""}
            className="pb-5"
        />
        <div className='ml-0 text-left'>
          <Typeahead<IBeneficiaryGroup>
            invalid={!!props.error?.group}
            invalidText={t(props.error?.group?.toString() || "")}
            label={t("beneficiary-group-label")}
            placeholder={t('beneficiary-group-search') as string}
            noOptionsMessage={t('search-option-none') as string}
            options={groupsPage?.results}
            onSelectOption={onGroupChange}
            search={(e) => getBeneficiaryGroups({pageNumber: 1, pageSize: 5}, [{
              key: "name_ic",
              value: e
            }]).then(res => res.results)}
            value={!props.item?.id ? undefined : {id: props.item?.group, name: props.item.groupName} as IBeneficiaryGroup}
          >
          </Typeahead>
        </div>
        <TextInput
            invalid={!!props.error?.address}
            invalidText={t(props.error?.address?.toString() || "")}
            labelText={t("address")!}
            name="address"
            onChange={(e: any) =>
                props.onChange(
                    {...props.item, address: e.target.value},
                    "address"
                )
            }
            required
            type="text"
            value={props.item.address || ""}
            className="pb-5"
        />
        {ToggleActive && (
            <>
              <Text className="wfp--label">{t("IsActive")}</Text>
              <Toggle
                  name="isActive"
                  defaultChecked={isActive}
                  checked={isActive}
                  labelA={t("no")}
                  labelB={t("yes")}
                  onChange={(e: any) => {
                    setIsActive(e.target.checked);
                    props.onChange(
                        {...props.item, isActive: e.target.checked},
                        "isActive"
                    );
                  }}
              />
            </>
        )}
      </div>
    </Form>
  );
}

export default BeneficiaryForm;
