import { useTranslation } from "react-i18next";
import { useState } from "react";
import { IBeneficiary } from "../../types/beneficiary";
import { validateBeneficiary, IBeneficiaryError } from "../../models/validate-beneficiary";
import { saveBeneficiary } from "../../services/beneficiary";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "@wfp/react";
import BeneficiaryForm from "./beneficiary-form";
import { useInvalidateBeneficiary } from "../../hooks/beneficiary";

interface Props {
  item: IBeneficiary;
  className?: string;
}
function BeneficiaryAddEdit(props: Props) {
  const [selectedItem, setSelectedItem] = useState<IBeneficiary>();
  const [itemError, setItemError] = useState<IBeneficiaryError>();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const labelTextKey = !!props.item.id ? t('beneficiary-edit') : t('beneficiary-add');
  const buttonText = !!props.item.id ? t("save") : t("create");
  const { mutate } = useInvalidateBeneficiary(props.item.id || 0);

  const handleAddItem = () => {
    if (!!props.item) {
      setSelectedItem(props.item);
    } else {
      setSelectedItem({} as IBeneficiary);
    }
    setOpenModal(true);
    setItemError(undefined);
  };

  const handleItemChange = (
    value: IBeneficiary,
    updatedField: "email" | "idmId" | "address" | "isActive" | "group"
  ) => {
    setItemError({ ...itemError, [updatedField]: undefined } as IBeneficiaryError);
    setSelectedItem(value);
  };

  const handleAddItemSubmit = () => {
    if (!!selectedItem) {
      validateBeneficiary(selectedItem)
        .then((beneficiary) =>
          saveBeneficiary(beneficiary)
            .then((res) => {
              setOpenModal(false);
              setItemError(undefined);
              setSelectedItem(res);
              mutate();
              navigate(`/beneficiary/${res.id}`);
            })
            .catch((e) => setItemError(e as IBeneficiaryError))
        )
        .catch((e) => setItemError(e));
    }
  };

  const handleAddItemClose = () => {
    setOpenModal(false);
    setItemError(undefined);
    setSelectedItem(undefined);
  };

  return (
    <div className={props.className}>
      <Button kind="primary" onClick={handleAddItem}>
        {t(labelTextKey)}
      </Button>
      <Modal
        open={openModal}
        onRequestClose={handleAddItemClose}
        onRequestSubmit={handleAddItemSubmit}
        modalHeading={t(labelTextKey)}
        primaryButtonText={buttonText}
        secondaryButtonText={t("cancel")}
        width="full"
        lazyLoad
        children={
          <BeneficiaryForm
            item={selectedItem!}
            onChange={handleItemChange}
            error={itemError}
          />
        }
      />
    </div>
  );
}

export default BeneficiaryAddEdit;
