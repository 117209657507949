import { useState } from 'react';
import { Button, Modal } from '@wfp/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IBeneficiaryGroup } from '../../types/beneficiary';
import { IBeneficiaryGroupError, validateBeneficiaryGroup } from '../../models/validate-beneficiary-group';
import { saveBeneficiaryGroup } from '../../services/beneficiary-group';
import BeneficiaryGroupForm from './beneficiary-group-form';
import { useInvalidateBeneficiaryGroup } from '../../hooks/beneficiary-group';


interface Props {
  item: IBeneficiaryGroup;
  className?: string;
}
function BeneficiaryGroupAddEdit(props: Props) {
  const [selectedItem, setSelectedItem] = useState<IBeneficiaryGroup>();
  const [itemError, setItemError] = useState<IBeneficiaryGroupError>();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const labelTextKey = !!props.item.id ? t('beneficiary-group-edit') : t('beneficiary-group-add');
  const buttonText = !!props.item.id ? t("save") : t("create");
  const { mutate } = useInvalidateBeneficiaryGroup(props.item.id || 0);

  const handleAddItem = () => {
    if (!!props.item) {
      setSelectedItem(props.item);
    } else {
      setSelectedItem({} as IBeneficiaryGroup);
    }
    setOpenModal(true);
    setItemError(undefined);
  };

  const handleItemChange = (
    value: IBeneficiaryGroup,
    updatedField: "nameAr" | "nameEn"
  ) => {
    setItemError({ ...itemError, [updatedField]: undefined } as IBeneficiaryGroupError);
    setSelectedItem(value);
  };

  const handleAddItemSubmit = () => {
    if (!!selectedItem) {
      validateBeneficiaryGroup(selectedItem)
        .then((beneficiary) =>
          saveBeneficiaryGroup(beneficiary)
            .then((res) => {
              setOpenModal(false);
              setItemError(undefined);
              setSelectedItem(res);
              mutate();
              navigate(`/beneficiary-group/${res.id}`);
            })
            .catch((e) => setItemError(e as IBeneficiaryGroupError))
        )
        .catch((e) => setItemError(e));
    }
  };

  const handleAddItemClose = () => {
    setOpenModal(false);
    setItemError(undefined);
    setSelectedItem(undefined);
  };

  return (
        <div className={props.className}>
            <Button kind="primary" onClick={handleAddItem}>
                {t(labelTextKey)}
            </Button>
            <Modal
                open={openModal}
                onRequestClose={handleAddItemClose}
                onRequestSubmit={handleAddItemSubmit}
                modalHeading={t(labelTextKey)}
                primaryButtonText={buttonText}
                secondaryButtonText={t("cancel")}
                width="full"
                lazyLoad
                children={
                    <BeneficiaryGroupForm
                        item={selectedItem!}
                        onChange={handleItemChange}
                        error={itemError}
                    />
                }
            />
        </div>
    );
}

export default BeneficiaryGroupAddEdit;
