import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/format";
import { IDelivery } from "../../types/delivery";
import {
  ConfigurableTable,
  TableProps,
} from "../commons/configurable-table/configurable-table";

function DeliveryTable(props: TableProps<IDelivery>) {
  const navigate = useNavigate();

  function handleRowClick(item: IDelivery) {
    navigate(`/delivery/${item.id}`);
  }

  return (
    <ConfigurableTable
      {...{
        ...props,
        onRowClick: handleRowClick,
        headers: ["Email", "State", "ScheduledFrom", "ScheduledTo"],
        fields: ["beneficiaryEmail", "state", "scheduledFrom", "scheduledTo"],
        sortables: [
          {
            field: "beneficiaryEmail",
            code: "beneficiary",
            mode: 0,
            enabled: true,
          },
          {
            field: "state",
            code: "state",
            mode: 0,
            enabled: true,
          },
          {
            field: "scheduledFrom",
            code: "scheduled_from",
            mode: 0,
            enabled: true,
          },
          {
            field: "scheduledTo",
            code: "scheduled_to",
            mode: 0,
            enabled: true,
          },
        ],
        formatters: {
          createdOn: (item: IDelivery) => formatDate(item.createdOn),
        },
      }}
    />
  );
}

export default DeliveryTable;
