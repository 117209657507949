import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbHome,
  Text,
  Button,
  Modal,
  Loading,
  BlockNotification,
  Search,
} from "@wfp/react";
import { Download } from "@wfp/icons-react";
import { deleteDelivery } from '../../services/delivery';
import { KVPair, PaginationParams, IServerError } from '../../types/commons';
import { IDelivery } from '../../types/delivery';
import { useGetCycleDeliveries, useInvalidateCycleDeliveries } from '../../hooks/delivery';
import { useActivateDeactivateCycle, useGetCycle } from "../../hooks/cycle";
import { useGetDeliveryReport } from "../../hooks/report";
import { LoadingCenter } from "../commons/loading-center";
import DeliveryTable from "../delivery/delivery-table";
import AddDelivery from "../delivery/delivery-add";
import CycleAddEdit from "./cycle-add-edit";
import { formatDate } from '../../utils/format';

function CycleDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: cycle, isLoading, isError } = useGetCycle(Number(id));
  const {
    mutate: activateDeactivate,
    isLoading: isLoadingActivateDeactivate,
    error: errorActivateDeactivate,
  } = useActivateDeactivateCycle(Number(id));

  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const [filters, setFilters] = useState<KVPair[] | undefined>();
  const [sorting, setSorting] = useState<string[] | undefined>(["beneficiary"]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const {
    data: deliveryPage,
    isLoading: isLoadingDeliveries,
    isError: isErrorDeliveries,
    refetch: refetchDeliveries,
  } = useGetCycleDeliveries(Number(id), paginationParams, sorting, filters);
  const {mutate: updateCycleDeliveries} = useInvalidateCycleDeliveries(Number(id))
  const {
    data,
    isLoading: isLoadingReport,
    isError: isErrorReport,
    refetch,
  } = useGetDeliveryReport(Number(id));
  const [openModal, setOpenModal] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState<IDelivery>();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [serverError, setServerError] = useState<IServerError>();

  const handleActivateDeactivate = () => {
    if (!!cycle) {
      activateDeactivate(cycle.isActive ? "deactivate" : "activate");
    }
    setOpenConfirmModal(false);
  };

  const [searchValue, setSearchValue] = useState<string>("");

  const handleOnSearch = (evt: any) => {
    const keyword = evt.target.value.toLowerCase();
    if (keyword.length >= 2) {
      setFilters([{ key: "beneficiary_ic", value: keyword }]);
    }
    if (keyword.length === 0) {
      setFilters(undefined);
    }
    setSearchValue(keyword);
  };
  const handleDeliveryDelete = (item: IDelivery) => {
    setConfirmDelete(true);
    setSelectedDelivery(item);
    setServerError(undefined);
    setOpenModal(true);
  }
  const handleDeliveryDeleteConfirm = () => {
    if (!!selectedDelivery && confirmDelete) {
      deleteDelivery(selectedDelivery.id)
        .then(() => {
          setOpenModal(false);
          setConfirmDelete(false);
          setSelectedDelivery(undefined);
          setServerError(undefined);
          updateCycleDeliveries();
      }).catch((e) => setServerError(e as IServerError));
    }
  };
  const handleDeliveryDeleteCancel = () => {
    setConfirmDelete(false)
    setSelectedDelivery(undefined);
    setServerError(undefined);
    setOpenModal(false);
  }
  return (
    <>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/#">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href="/cycle">{t("Cycles")}</BreadcrumbItem>
        <BreadcrumbItem disableLink>{cycle?.name}</BreadcrumbItem>
      </Breadcrumb>
      {isLoading && <LoadingCenter />}
      {isError && <div>Error...</div>}
      {!!cycle && (
          <div className="mt-8">
            <div className="text-justify">
              <div className="flex justify-between align-middle">
                <Text kind="story-title" className="text-2xl text-justify">
                  {t('Details')}
                </Text>
                <div className="flex justify-end space-x-2">
                  <CycleAddEdit item={cycle} kind={'secondary'}/>
                  <AddDelivery cycleId={cycle.id}/>
                  <Button
                      kind="primary"
                      onClick={() => setOpenConfirmModal(true)}
                      disabled={isLoadingActivateDeactivate}
                  >
                    {cycle.isActive ? t('Deactivate') : t('Activate')}
                  </Button>
                </div>
              </div>
              {!!errorActivateDeactivate && (
                  <BlockNotification
                      hideCloseButton={false}
                      kind="error"
                      title={t('Error')}
                      subtitle={t('ActivationError')}
                  />
              )}
              <div className="w-1/5">
                <div className="flex justify-between mb-3">
                  <span className="text-gray-500 ">{t('Name')}</span>
                  <p> {cycle.name}</p>
                </div>
                <div className="flex justify-between mb-3">
                  <span className="text-gray-500">{t('DateFrom')}: </span>
                  <p>{formatDate(cycle.dateFrom)}</p>
                </div>
                <div className="flex justify-between mb-3">
                  <span className="text-gray-500">{t('DateTo')}: </span>
                  <p>{formatDate(cycle.dateTo)}</p>
                </div>
              </div>
            </div>
            <div className="text-left mt-8">
              <div className="flex align-middle justify-between">
                <Text kind="story-title" className="text-2xl text-justify">
                  {t('delivery-list')}
                </Text>
                {!!deliveryPage && deliveryPage.count > 0 && (
                    <div>
                      <Button
                          onClick={() => refetch()}
                          kind="secondary"
                          icon={Download}
                          disabled={isLoadingReport}
                      >
                        {t('Export')}
                      </Button>
                    </div>
                )}
              </div>
              {isLoadingDeliveries && <LoadingCenter/>}
              {isErrorDeliveries && <div>Error...</div>}
              {!!deliveryPage && deliveryPage.count > 0 && (
                  <>
                  <div className="w-1/5 mb-3">
                    <Search
                        className="filter-beneficiary"
                        name="beneficiary-email"
                        onChange={handleOnSearch}
                        value={searchValue}
                    />
                  </div>
                  <div className="flex-col">
                    <DeliveryTable page={deliveryPage}
                     onChangePagination={setPaginationParams}
                     onChangeSorting={setSorting}
                     onDeleteRow={handleDeliveryDelete}
                    />
                  </div>
                  </>
              )}
              {!!deliveryPage && deliveryPage.count === 0 && (
                  <BlockNotification
                      hideCloseButton={false}
                      kind="info"
                      title={t('NoDeliveries')}
                      subtitle={t('NoDeliveriesDescription')}
                  />
              )}
            </div>
          </div>
      )}
      {openConfirmModal && (
        <Modal
          open={true}
          onRequestSubmit={handleActivateDeactivate}
          onRequestClose={() => setOpenConfirmModal(false)}
          modalHeading={t("Confirm")}
          primaryButtonText={t("Confirm")}
          secondaryButtonText={t("cancel")}
          width="full"
          children={
            <>
              <Text>
                {cycle!.isActive ? t("cycle-deactivate-confirm") : t("cycle-activate-confirm")}
              </Text>
              {isLoadingActivateDeactivate && (
                <Loading withOverlay={false} small />
              )}
            </>
          }
        />
      )}
      {openModal && !!selectedDelivery?.id && confirmDelete && (
        <Modal
          open={true}
          onRequestClose={handleDeliveryDeleteCancel}
          onRequestSubmit={handleDeliveryDeleteConfirm}
          modalHeading={t("delivery-delete")}
          primaryButtonText={t("Yes")}
          secondaryButtonText={t("No")}
          width="full"
        >
          <Text>{t("delivery-delete-confirm")}</Text>
          {serverError && (
            <Text className="text-red-500" kind="error">{serverError?.errors?.toString()}</Text>
          )}
        </Modal>
      )}
    </>
  );
}

export default CycleDetail;
