import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/format";
import { ICycle } from "../../types/cycle";
import { ConfigurableTable, TableProps } from '../commons/configurable-table/configurable-table';

function CycleTable(props: TableProps<ICycle>) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleRowClick(item: ICycle) {
    navigate(`/cycle/${item.id}/`);
  }

  return (
        <ConfigurableTable {...{
          ...props,
          onRowClick: handleRowClick,
          headers: ["IsActive", "Name",  "DateFrom", "DateTo"],
          fields: ["isActive", "name",  "dateFrom", "dateTo"],
          sortables: [
              { field: "isActive", code: "is_active", mode: 0, enabled: true },
              { field: "name", code: "name", mode: 0, enabled: true },
              { field: "DateFrom", code: "date_from", mode: 0, enabled: true },
              { field: "DateTo", code: "date_to", mode: 0, enabled: true },
          ],
          formatters: {
            dateFrom: (item: ICycle) => formatDate(item.dateFrom),
            dateTo: (item: ICycle) => formatDate(item.dateTo),
            isActive: (item: ICycle) => item.isActive ? t('Active') : t('Inactive')
          }
        }} />
  );
}

export default CycleTable;
