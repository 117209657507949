import {IServerError} from "../types/commons";
import {IBeneficiaryGroup} from "../types/beneficiary";


export interface IBeneficiaryGroupError extends IServerError {
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
}

class BeneficiaryGroupErrorInfo implements IBeneficiaryGroupError {
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateBeneficiaryGroup(item: IBeneficiaryGroup): Promise<IBeneficiaryGroup> {
    const error = new BeneficiaryGroupErrorInfo();
    if (!item.nameAr) {
        error.add('nameAr', 'this_field_is_required');
    }
    if (!item.nameEn) {
        error.add('nameEn', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(item) : Promise.reject(error);
}
