import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BlockNotification, Text, useMediaQuery } from '@wfp/react';
import { KVPair, PaginationParams } from '../../types/commons';
import { Role } from '../../types/user';
import { IBeneficiaryGroup } from '../../types/beneficiary';
import { useGetBeneficiaryGroups } from '../../hooks/beneficiary-group';
import { LoadingCenter } from '../commons/loading-center';
import BeneficiaryGroupTable from './beneficiary-group-table';
import BeneficiaryGroupAddEdit from './beneficiary-group-add-edit';
import { useLoggedUser } from '../../hooks/user';

function BeneficiaryGroupPage() {
  const user = useLoggedUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({ pageNumber: 1 });
  const [sorting, setSorting] = useState<string[] | undefined>(['id']);
  const [filters, setFilters] = useState<KVPair[] | undefined>(undefined);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const { data: page, isLoading, isError } = useGetBeneficiaryGroups(paginationParams, filters, sorting);

  return (
      <>
          <div className="mt-10 ">
              <div className="flex justify-between">
                  <Text kind="story-title" className="text-2xl text-left">
                      {t("beneficiary-group-list")}
                  </Text>
              {!isSmallScreen && ([Role.WFPManager, Role.WFPStaff] as (Role | undefined)[]).includes(user?.role) && <BeneficiaryGroupAddEdit item={{} as IBeneficiaryGroup}/>}
              </div>
              {isLoading && <LoadingCenter/>}
              {isError && <div>Error...</div>}
              {!!page && (
                  <>
                      {page.count === 0 && <BlockNotification
                          kind="info"
                          title={t("NoBeneficiaryGroups")}
                          subtitle={t("NoBeneficiaryGroupsDescription")}
                      />}
                      {isSmallScreen ? (
                          <>
                              {page.results.map((item) => (
                                  <div className="m-5 wfp--card-box p-4" key={item.id}
                                       onClick={() => navigate(`/basket/${item.id}`)}>
                                      <div className="text-left m-4">
                                          <div className="flex justify-between mb-3">
                                              <span className="text-gray-500">{t("ID")}: </span>
                                              <p>{item.id}</p>
                                          </div>
                                          <div className="flex justify-between mb-3">
                                              <span className="text-gray-500">{t("Name")}: </span>
                                              <p>{item.name}</p>
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </>
                      ) : (
                          <div className="flex-col">
                              <BeneficiaryGroupTable page={page}
                                                     onChangePagination={setPaginationParams}
                                                     onChangeSorting={setSorting}
                              />
                          </div>
                      )}
                  </>
              )}
          </div>
      </>
  );
}

export default BeneficiaryGroupPage;
