import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneOptions, FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import { Button, Text } from '@wfp/react';
import { Delete, Upload } from '@wfp/icons-react';
import { useNotification } from '../../hooks/notification';
import { IServerError } from '../../types/commons';

export interface IErrorFileUpload extends IServerError {
  file: string[],
}

interface IFileUploadProps
  extends Partial<Pick<DropzoneOptions, 'onDrop' | 'accept' | 'maxFiles' | 'maxSize'>> {
  files?: Array<File>;
  showUploadedFiles?: boolean;
  onRemoveFile?: (file: FileWithPath) => void;
  onDropRejected?: (errors: FileRejection[]) => void;
  error?: IErrorFileUpload;
}

const FileUpload: React.FC<IFileUploadProps> = ({
  onDrop,
  accept,
  showUploadedFiles,
  onRemoveFile,
  files,
  maxFiles,
  maxSize,
  onDropRejected,
  error
}) => {
  const { t } = useTranslation();
  const { show } = useNotification();

  const onError = useCallback(
    (fileRejections: FileRejection[]) => {
      const errorCodes = new Set(fileRejections.map((fileRejection) => fileRejection.errors[0].code));
      errorCodes.forEach((error) =>
        show(t('error'), t(error.replaceAll('-', '_')), { kind: 'error', timeout: 3000 })
      );
    },
    [show, t]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
    onDropRejected: onDropRejected || onError
  });

  const additionalClasses = useMemo(
    () =>
      'wfp--dropzone__input' +
      `${isFocused ? ' wfp--dropzone__input--focused' : ''}` +
      `${isDragAccept ? ' wfp--dropzone__input--accepted' : ''}` +
      `${isDragReject ? ' wfp--dropzone__input--rejected' : ''}`,
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div className='wfp--dropzone'>
        <div className={` ${additionalClasses}`} {...getRootProps()}>
          <div className='mb-3'>
            <Button kind="primary" icon={Upload}>{t("upload")}</Button>
          </div>
          <input {...getInputProps()} />
          <p className='font-size-small'>{t('drag-and-drop')}</p>
        </div>
        {showUploadedFiles && <FileDisplayContainer files={files} onRemoveFile={onRemoveFile} />}
          {!!error && (
            <p >
              <Text className="text-red-500" kind="error">
                  {t((error.file || error.errors)?.toString())}
              </Text>
            </p>
          )}
      </div>
    </>
  );
};

interface IFileDisplayContainerProps extends Pick<IFileUploadProps, 'onRemoveFile'> {
  files?: readonly FileWithPath[];
}

export const FileDisplayContainer: React.FC<IFileDisplayContainerProps> = ({ files, onRemoveFile }) => {
  const { t } = useTranslation();
  if (!files) return (<></>)

  return (
    <div className='wfp--file-container'>
      <span className='wfp--file-container__title'>{t('files')}</span>
      {files.map((file, idx) => (
        <FileDisplayItem
          key={idx}
          file={file}
          onRemoveFile={onRemoveFile}
        />
      ))}
    </div>
  );
};

interface IFileDisplayItem extends Pick<IFileUploadProps, 'onRemoveFile'> {
  file: FileWithPath;
}

export const FileDisplayItem: React.FC<IFileDisplayItem> = ({ file, onRemoveFile }) => {
  return (
    <span className='wfp--file__selected-file font-size-small'>
      {file.name} ({ Math.ceil(file.size/1024) }kb)
      <span className='wfp--file__state-container'>
        <div
          onClick={() => (onRemoveFile ? onRemoveFile(file) : null)}
          data-testid='file-display-delete'
        >
          <Button kind="danger" icon={Delete} />
        </div>
      </span>
    </span>
  );
};

export default FileUpload;
