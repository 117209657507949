import { useNavigate } from "react-router-dom";
import { IBeneficiaryGroup } from '../../types/beneficiary';
import { ConfigurableTable, TableProps } from '../commons/configurable-table/configurable-table';

function BeneficiaryGroupTable(props: TableProps<IBeneficiaryGroup>) {
  const navigate = useNavigate();

  function handleRowClick(item: IBeneficiaryGroup) {
    navigate(`/beneficiary-group/${item.id}/`);
  }

  return (
      <ConfigurableTable {...{
            ...props,
            onRowClick: handleRowClick,
            headers: ["ID", "Name", "Count"],
            fields: ["id", "name", "size"],
            sortables: [
                { field: "id", code: "id", mode: 0, enabled: true },
                { field: "name", code: "name", mode: 0, enabled: true },
                { field: "size", code: "size", mode: 0, enabled: false },
            ]
        }} />
  );
}

export default BeneficiaryGroupTable;
