import { IBeneficiaryGroup } from "../types/beneficiary";
import { formatQueryOptions, restapi } from "./restapi";
import { PaginationParams, KVPair, ListPage } from "../types/commons";


export function getBeneficiaryGroups(params: PaginationParams, filters?: KVPair[], sorting?: string[]): Promise<ListPage<IBeneficiaryGroup>> {
    const qs = formatQueryOptions(params, filters, sorting);
    return restapi.get<ListPage<IBeneficiaryGroup>>(`/api/v1/salaty_core/beneficiary_group/?${qs}`).then((res) => ({...res.data, filtering: filters, sorting: sorting}));
}

export function getBeneficiaryGroupDetail(id: number): Promise<IBeneficiaryGroup> {
    return restapi.get<IBeneficiaryGroup>(`/api/v1/salaty_core/beneficiary_group/${id}/`).then((res) => res.data);
}

export function disableBeneficiaryGroup(id: number): Promise<IBeneficiaryGroup> {
    return restapi.post<IBeneficiaryGroup>(`/api/v1/salaty_core/beneficiary_group/${id}/disable/`).then(res => res.data);
}


export function saveBeneficiaryGroup(data: IBeneficiaryGroup): Promise<IBeneficiaryGroup> {
    if (!data.id) {
        return restapi.post<IBeneficiaryGroup>('/api/v1/salaty_core/beneficiary_group/', data).then((res) => res.data);
    } else {
        return restapi.patch<IBeneficiaryGroup>(`/api/v1/salaty_core/beneficiary_group/${data.id}/`, data).then((res) => res.data);
    }
}
