import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockNotification, Button, Modal, Text, useMediaQuery } from '@wfp/react';
import { Role } from '../../types/user';
import { KVPair, PaginationParams } from '../../types/commons';
import { useLoggedUser } from '../../hooks/user';
import useGetBeneficiaries from "../../hooks/beneficiary";
import { LoadingCenter } from '../commons/loading-center';
import FileUpload, { FileDisplayItem, IErrorFileUpload } from '../commons/file-upload';
import BeneficiaryAddEdit from "./beneficiary-add-edit";
import BeneficiaryCard from "./beneficiary-card";
import BeneficiaryTable from "./beneficiary-table";
import { IBeneficiary } from '../../types/beneficiary';
import { FileWithPath } from 'react-dropzone';
import { useNotification } from '../../hooks/notification';
import { uploadBulkFile } from '../../services/beneficiary';

function BeneficiaryPage() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const [filters, setFilters] = useState<KVPair[] | undefined>();
  const [sorting, setSorting] = useState<string[] | undefined>();
  const { data: page, isLoading, isError } = useGetBeneficiaries(paginationParams, filters, sorting);
  const user = useLoggedUser();
  const [file, setFile] = useState<FileWithPath>();
  const [errorFile, setErrorFile] = useState<IErrorFileUpload>();

  const { show } = useNotification();
  /*
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<IBeneficiary | undefined>();

  const handleOnSearch = (evt: any) => {
    const keyword = evt.target.value.toLowerCase();
    if (keyword.length > 2) {
      setFilters([{key: 'email_ic', value: keyword}]);
    } else {
      setSelectedBeneficiary(undefined);
    }
    setSearchValue(keyword);
  };

  const onClickBeneficiary = (value: IBeneficiary) => {
    setSelectedBeneficiary(value);
    setSearchValue(value.email);
  }
          <Search
            className="search-sidebar"
            name="input-name"
            labelText={t("Search")}
            onChange={handleOnSearch}
            value={searchValue}
          />
          <div className="wfp--sidebar-item-content sidebar__container border-0 max-h-screen">
              <BeneficiaryList beneficiaries={beneficiariesPage.results} onClick={onClickBeneficiary} />
          </div>
          <div className="wfp--content-section">
            <div className="ml-5">
              {!!selectedBeneficiary && <BeneficiaryDetail instance={selectedBeneficiary} />}
            </div>
          </div>
*/
  const handleBulkAdd = () => {
      setOpenModal(true);
  }

  const handleBulkSubmit = () => {
      if (!file) {
          return;
      }
      uploadBulkFile(file).then((res) => {
          if (!!res.errors && res.errors.length > 0) {
              const msg = '\r\n'.concat(res.errors);
              show(t('error'), msg, {kind: 'error', timeout: 10000})
          } else {
              show(`${t('file')}: ${file.name}`, t('beneficiary-bulk-results', {created: res.created, updated: res.updated}), {kind: 'success', timeout: 3000})
          }
      }).catch((error) => {
          show(t('error'), error.detail, {kind: 'error'})
      }).finally(() => setFile(undefined))
      setOpenModal(false);
  }

  const handleBulkClose = () => {
      setOpenModal(false);
      setFile(undefined);
      setErrorFile(undefined);
  }

  const handleBulkFileDrop = (files: any[]) => {
      setFile(!!files ? files[0] : undefined);
      setErrorFile(undefined);
  }

  const handleRemoveFile = () => {
    setFile(undefined);
    setErrorFile(undefined);
  }

  return (
      <div className="mt-10">
          <div className="flex justify-between">
            <Text kind="story-title" className="text-2xl text-left">
              {t("Beneficiaries")}
            </Text>
            {!isSmallScreen && ([Role.WFPManager, Role.WFPStaff] as (Role | undefined)[]).includes(user?.role) && (
                <div className="flex gap-2">
                    <Button kind="secondary" onClick={handleBulkAdd}>{t('beneficiary-bulk-button')}</Button>
                    <BeneficiaryAddEdit item={{} as IBeneficiary}/>
                </div>
            )}
          </div>
          {isLoading && <LoadingCenter/>}
          {isError && <div>Error</div>}
          {!!page && (
            <>
              {page.count === 0 && <BlockNotification
                  kind="info"
                  title={t('NoBeneficiaries')}
                  subtitle={t("NoBeneficiariesDescription")} />}
            {isSmallScreen ? (
              <>
                  {(page.results).map((item) => (
                    <div  className="m-5 wfp--card-box p-4 flex flex-col">  
                      <BeneficiaryCard key={item.id} item={item} />
                    </div>
                  ))}
              </>
            ) : (
                <BeneficiaryTable
                  page={page}
                  onChangePagination={setPaginationParams}
                  onChangeSorting={setSorting}
                />
            )}
            </>)}
              <Modal
                open={openModal}
                onRequestClose={handleBulkClose}
                onRequestSubmit={handleBulkSubmit}
                modalHeading={t('beneficiary-bulk-title')}
                primaryButtonText={t("upload")}
                primaryButtonDisabled={!file}
                secondaryButtonText={t("cancel")}
                width="full"
                lazyLoad
                children={
                    <>
                        {!file ? (
                            <FileUpload
                                accept={{'text/html': ['.csv']}} files={!!file ? [file] : undefined}
                                onDrop={handleBulkFileDrop}
                                onDropRejected={(rejections) => setErrorFile({
                                    ...errorFile,
                                    file: rejections[0].errors.map(e => e.code)
                                })}
                                maxFiles={1}
                                error={errorFile}
                            />
                        ) : (
                            <div className="wfp--file-container">
                                <span className="wfp--file-container__title">{t('file')}</span>
                                <FileDisplayItem file={file} onRemoveFile={handleRemoveFile}/>
                            </div>
                        )}
                        <div className="mt-4 text-sm">
                            <div>
                                <span className="text-lg"><strong>{t('beneficiary-bulk-help-title')}</strong></span>
                                <span className="mx-3">{t('beneficiary-bulk-help-line')}</span>
                            </div>
                            <div className="wfp--blockquote">
                                <div className="grid grid-cols-5 gap-x-0.5 gap-y-2 p-5">
                                    <div>#IDMID;</div>
                                    <div>email;</div>
                                    <div>address;</div>
                                    <div>group (AR);</div>
                                    <div>group (EN)</div>
                                    <div>00001;</div>
                                    <div>user1@email.net;</div>
                                    <div>address 1;</div>
                                    <div>المستفيدون;</div>
                                    <div>Beneficiaries</div>
                                    <div>00002;</div>
                                    <div>user2@email.net;</div>
                                    <div>address 2;</div>
                                    <div>المستفيدون;</div>
                                    <div>Beneficiaries</div>
                                </div>
                            </div>
                            <div className="mt-2 text-xs">
                                <strong>{t('beneficiary-bulk-note-title')}</strong>: {t('beneficiary-bulk-note-line')}
                            </div>
                        </div>
                    </>
                }
              />
      </div>
  );
}

export default BeneficiaryPage;
