import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ICycle } from "../../types/cycle";
import { formatDate } from "../../utils/format";

interface Props {
  item: ICycle;
}

function CycleCard(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();


  return (
    <div onClick={() => navigate(`/cycle/${props.item.id}`)} className="m-5 wfp--card-box p-4 flex flex-col">
      <div className="flex flex-col justify-between">
        <div className="flex mb-2 justify-between">
          <span className="text-gray-500 ">{t('ID')}</span>
          <p>{props.item.id}</p>
        </div>
        <div className="flex mb-2 justify-between">
          <span className="text-gray-500 ">{t('Description')}</span>
          <p>{props.item.description}</p>
        </div>
        <div className="flex mb-2 justify-between">
          <span className="text-gray-500">{t('DateFrom')}</span>
          <p>{formatDate(props.item.dateFrom)}</p>
        </div>
        <div className="flex mb-2  justify-between">
          <span className="text-gray-500 ">{t('DateTo')}</span>
          <p>{formatDate(props.item.dateTo)}</p>
        </div>
      </div>
    </div>
  );
}

export default CycleCard;
