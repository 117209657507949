import { useTranslation } from 'react-i18next';
import { Form, TextInput } from '@wfp/react';
import { IBeneficiaryGroup } from '../../types/beneficiary';
import { IBeneficiaryGroupError } from '../../models/validate-beneficiary-group';

interface BeneficiaryGroupFormProps {
  item: IBeneficiaryGroup;
  error?: IBeneficiaryGroupError;
  onChange: (item: IBeneficiaryGroup, field: 'nameAr' | 'nameEn') => void;
}

function BeneficiaryGroupForm(props: BeneficiaryGroupFormProps) {
  const { t} = useTranslation();

  return (
    <div>
      <Form>
        <TextInput
          invalid={!!props.error?.nameAr}
          invalidText={t(props.error?.nameAr?.toString() || '')}
          labelText={t('nameAr')!}
          name='nameAr'
          onChange={(e: any) => props.onChange({...props.item, nameAr: e.target.value}, 'nameAr')}
          required
          type='text'
          value={props.item.nameAr || ''}
        />
        <TextInput
          invalid={!!props.error?.nameEn}
          invalidText={t(props.error?.nameEn?.toString() || '')}
          labelText={t('nameEn')!}
          name='nameEn'
          onChange={(e: any) => props.onChange({...props.item, nameEn: e.target.value}, 'nameEn')}
          required
          type='text'
          value={props.item.nameEn || ''}
        />
      </Form>
    </div>
  );
}

export default BeneficiaryGroupForm;
