import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Footer, Wrapper } from "@wfp/react";
import { Role } from "./types/user";
import { useLoggedUser } from './hooks/user';
import { Home } from "./screens/home";
import LogoutDone from './screens/logout-done';
import MobileHome from "./mobile/home";
import { locationRoleChecker, RouteGuard, RouteGuardEntry } from './components/commons/route-guard/route-guard';
import { Header } from "./components/layouts/header/Header";
import DeliveryPage from "./components/delivery/delivery-page";
import BasketPage from './components/basket/basket-page';
import BasketDetail from "./components/basket/basket-detail";
import BeneficiaryPage from './components/beneficiary/beneficiary-page';
import BeneficiaryGroupPage from './components/beneficiary-group/beneficiary-group-page';
import BeneficiaryGroupDetail from './components/beneficiary-group/beneficiary-group-detail';
import BeneficiaryDetail from "./components/beneficiary/beneficiary-detail";
import UserPage from './components/user/user-page';
import UserDetail from './components/user/user-detail';
import CyclePage from './components/cycle/cycle-page';
import CycleDetail from "./components/cycle/cycle-detail";
import DeliveryDetail from './components/delivery/delivery-detail';


export const RouteGuardConfig: RouteGuardEntry[] = [
    { label: 'Home', routing: '/mobile', roles: [Role.Beneficiary] },
    { label: 'Beneficiaries', routing: '/beneficiary', roles: [Role.WFPManager, Role.WFPStaff] },
    { label: 'Groups', routing: '/beneficiary-group', roles: [Role.WFPManager, Role.WFPStaff] },
    { label: 'Baskets', routing: '/basket', roles: [Role.WFPManager, Role.WFPStaff] },
    { label: 'Cycles', routing: '/cycle', roles: [Role.WFPManager, Role.WFPStaff] },
    { label: 'Users', routing: '/user', roles: [Role.WFPManager] },
    { routing: '/delivery', roles: [Role.WFPManager, Role.WFPStaff] },
];


const UserRouteGuard = () => {
    const user = useLoggedUser();
    return (
        !user ? null : <RouteGuard fallback="/home" authorize={(l) => locationRoleChecker(user?.role || null, l, RouteGuardConfig)} />
    )
}

function AppRouting() {
  return (
    <Router>
      <Header />
        <Wrapper className="wfp--layout__body !mt-0" spacing='xl'  pageWidth="lg">
        <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/public" element={<LogoutDone />} />
            <Route path="/" element={<UserRouteGuard />}>
                <Route path="/mobile" element={<MobileHome />} />
                <Route path="/mobile/delivery" element={<DeliveryPage />} />
                <Route path="/home" element={<Home />} />
                <Route path="/beneficiary" element={<BeneficiaryPage />} />
                <Route path="/beneficiary/:id" element={<BeneficiaryDetail />} />
                <Route path="/beneficiary-group" element={<BeneficiaryGroupPage />} />
                <Route path="/beneficiary-group/:id" element={<BeneficiaryGroupDetail />} />
                <Route path="/basket" element={<BasketPage />} />
                <Route path="/basket/:id" element={<BasketDetail />} />
                <Route path="/cycle" element={<CyclePage />} />
                <Route path="/cycle/:id" element={<CycleDetail />} />
                <Route path="/delivery/:id" element={<DeliveryDetail />} />
                <Route path="/user" element={<UserPage />} />
                <Route path="/user/:id" element={<UserDetail />} />
            </Route>
            <Route path='*' element={<Navigate to='/home' replace />} />
        </Routes>
        </Wrapper>
        <Footer labelOne="2024 © World Food Programme" labelTwo="Salaty v1.0-beta"/>
    </Router>
  );
}

export default AppRouting;
