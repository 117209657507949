import { format } from "date-fns";

export function formatTime(date: string) {
    return new Date(date).toLocaleTimeString();
}

export function formatDate(date: string | Date) {
    return new Date(date).toLocaleDateString();
}

export function formatDateTime(date: string | Date) {
    const d = new Date(date);
    return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
}

export function formatTimeSlot(dateFrom: string, dateTo: string): string {
    return `${formatTime(dateFrom)} - ${formatTime(dateTo)}`;
}

export function dateToString(date: string | Date) {
    return format(new Date(date), "yyyy-MM-dd");
}

export function dateTimeToString(date: string | Date) {
    return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
}
