import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IBeneficiary } from "../../types/beneficiary";
import { Text, useMediaQuery } from '@wfp/react';

interface Props {
  item: IBeneficiary;
  readOnly?: boolean;
}

function BeneficiaryCard(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  function handleClick() {
    if (!props.readOnly) {
      navigate(`/beneficiary/${props.item.id}/`);
    }
  }

  return (
    <div onClick={handleClick} className={`${isSmallScreen ? "wfp--card-box p-4 flex flex-col" : ""} `}>
      <div className="flex flex-col justify-between">
          <div className={`${isSmallScreen ? "m-4" : ""} text-left`}>
            <div className="flex justify-between mb-3">
              <span className="text-gray-500">{t("Email")}: </span>
              <p>{props.item.email}</p>
            </div>
            <div className="flex justify-between mb-3">
              <Text className="text-gray-500">{t("IDMID")}: </Text>
              <Text className="justify-end">
                {t(props.item.idmId)}
              </Text>
            </div>
            <div className="flex justify-between mb-3">
              <Text className="text-gray-500">{t("address")}: </Text>
              <Text className="justify-end">
                {props.item.address}
              </Text>
            </div>
          </div>
        </div>
      </div>
   
  );
}

export default BeneficiaryCard;
