import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockNotification, Text, useMediaQuery } from '@wfp/react';
import { KVPair, PaginationParams } from "../../types/commons";
import { Role } from "../../types/user";
import { useLoggedUser } from "../../hooks/user";
import { useGetCycles } from "../../hooks/cycle";
import CycleCard from "./cycle-card";
import CycleTable from "./cycle-table";
import CycleAddEdit from "./cycle-add-edit";
import { LoadingCenter } from '../commons/loading-center';
import { ICycle } from '../../types/cycle';

function CyclePage() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const [filters, setFilters] = useState<KVPair[] | undefined>(undefined);
  const [sorting, setSorting] = useState<string[] | undefined>(["-id"]);
  const { data: cyclesPage , isLoading, isError } = useGetCycles(paginationParams, filters, sorting);
  const user = useLoggedUser();

  return (
    <>
      <div className="mt-10">
        <div className="flex justify-between">
          <Text kind="story-title" className="text-2xl text-left">
            {t("Cycles")}
          </Text>
          {!isSmallScreen && ([Role.WFPManager, Role.WFPStaff] as (Role | undefined)[]).includes(user?.role) && <CycleAddEdit item={{} as ICycle} />}
        </div>
        {isLoading && <LoadingCenter />}
        {isError && <div>Error</div>}
        {!!cyclesPage && (
          <>
            {cyclesPage.count === 0 && <BlockNotification
              kind="info"
              title={t("NoCycles")}
              subtitle={t("NoCyclesDescription")}
            />}
            {isSmallScreen ? (
              <>
                {cyclesPage.results.map((item) => (
                  <CycleCard key={item.id} item={item} />
                ))}
              </>
            ) : (
              <CycleTable
                page={cyclesPage}
                onChangePagination={setPaginationParams}
                onChangeSorting={setSorting}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default CyclePage;
