import { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbHome,
  BlockNotification,
  Button,
  Text,
  Modal,
  Loading,
} from "@wfp/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PaginationParams } from '../../types/commons';
import { DeliveryState } from "../../types/delivery";
import { useGetBeneficiary } from "../../hooks/beneficiary";
import {
  useRollbackBookedDelivery,
  useProgressBookedDelivery,
  useGetBasketDeliveryList,
  useGetDelivery, useProgressConfirmedDelivery, useRollbackConfirmedDelivery,
} from '../../hooks/delivery';
import { LoadingCenter } from "../commons/loading-center";
import BeneficiaryCard from "../beneficiary/beneficiary-card";
import DeliveryBasketTable from "./delivery-basket-table";
import DeliveryEdit from "./delivery-edit";
import { formatDate, formatTimeSlot } from '../../utils/format';

enum Action {
  BOOKED_PROGRESS = 1,
  BOOKED_ROLLBACK,
  CONFIRMED_PROGRESS = 3,
  CONFIRMED_ROLLBACK,
}

function DeliveryDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [pagination, setPagination] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const [sorting, setSorting] = useState<string[] | undefined>(["basket"]);

  const [action, setAction] = useState<Action>();

  const {
    data: delivery,
    isLoading,
    isError,
    refetch: refetchDelivery,
  } = useGetDelivery(Number(id));
  const {
    data: basketDeliveryList,
    isLoading: isLoadingBasketDeliveryList,
    isError: isErrorBasketDeliveryList,
    refetch: refetchBasketDeliveryList,
  } = useGetBasketDeliveryList(Number(id), !!delivery?.id, pagination, undefined, sorting);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const {
    mutate: progressBookedDelivery,
    isLoading: isLoadingProgressBooked,
    error: errorProgressBooked,
  } = useProgressBookedDelivery(Number(id));
  const {
    mutate: rollbackBookedDelivery,
    isLoading: isLoadingRollbackBooked,
    error: errorRollbackBooked,
  } = useRollbackBookedDelivery(Number(id));
  const {
    mutate: progressConfirmedDelivery,
    isLoading: isLoadingProgressConfirmed,
    error: errorProgressConfirmed,
  } = useProgressConfirmedDelivery(Number(id));
  const {
    mutate: rollbackConfirmedDelivery,
    isLoading: isLoadingRollbackConfirmed,
    error: errorRollbackConfirmed,
  } = useRollbackConfirmedDelivery(Number(id));
  const {
    data: beneficiary,
  } = useGetBeneficiary(Number(delivery?.beneficiary), !!delivery);

  const handleModalSubmit = () => {
    if (!action) {
      return;
    }
    if (action === Action.BOOKED_PROGRESS) {
      progressBookedDelivery();
    }
    if (action === Action.BOOKED_ROLLBACK) {
      rollbackBookedDelivery();
    }
    if (action === Action.CONFIRMED_PROGRESS) {
      progressConfirmedDelivery();
    }
    if (action === Action.CONFIRMED_ROLLBACK) {
      rollbackConfirmedDelivery();
    }
    refetchDelivery().then(()=> {});
    setOpenConfirmModal(false);
    setAction(undefined);
  }
  const handleModalCancel = () => {
    setOpenConfirmModal(false);
    setAction(undefined);
  }
  const handleBookedRollback =  () => {
    setAction(Action.BOOKED_ROLLBACK);
    setOpenConfirmModal(true);
  }
  const handleBookedProgress =  () => {
    setAction(Action.BOOKED_PROGRESS);
    setOpenConfirmModal(true);
  }
  const handleConfirmedRollback =  () => {
    setAction(Action.CONFIRMED_ROLLBACK);
    setOpenConfirmModal(true);
  }
  const handleConfirmedProgress =  () => {
    setAction(Action.CONFIRMED_PROGRESS);
    setOpenConfirmModal(true);
  }

  return (
    <div>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/#">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href={'/cycle'}>{t("Cycles")}</BreadcrumbItem>
        <BreadcrumbItem href={`/cycle/${delivery?.cycle}`}>
          {!!delivery?.cycleName ? delivery.cycleName : ""}
        </BreadcrumbItem>
        <BreadcrumbItem disableLink>
          {!!beneficiary ? beneficiary.idmId : t("Delivery")}
        </BreadcrumbItem>
      </Breadcrumb>
      {isLoading && <LoadingCenter />}
      {isError && <div>{t("Error")}</div>}
      {!!errorRollbackConfirmed && (
        <BlockNotification kind="error">{t("errorRollbackConfirmed")}</BlockNotification>
      )}
      {!!errorProgressConfirmed && (
        <BlockNotification kind="error">{t("errorProgressConfirmed")}</BlockNotification>
      )}
      {!!errorRollbackBooked && (
        <BlockNotification kind="error">{t("errorRollbackBooked")}</BlockNotification>
      )}
      {!!errorProgressBooked && (
        <BlockNotification kind="error">{t("errorProgressBooked")}</BlockNotification>
      )}
      {!!delivery && (
        <div className="mt-8">
          <div className="text-justify">
            <div className="flex justify-between align-middle">
              <Text kind="story-title" className="text-2xl text-justify">
                {t("Details")}
              </Text>
              <div className="flex justify-between">
                <DeliveryEdit className="mx-1" item={delivery} onUpdate={() => refetchBasketDeliveryList().then(()=> {})}/>
                {delivery.state === DeliveryState.BOOKED && (
                  <>
                    <Button className="mx-1"
                      kind="danger"
                      onClick={handleBookedRollback}
                      disabled={isLoadingProgressBooked || isLoadingRollbackBooked}
                    >
                      {t("delivery-assign")}
                    </Button>
                    <Button className="mx-1"
                      kind="primary"
                      onClick={handleBookedProgress}
                      disabled={isLoadingProgressBooked || isLoadingRollbackBooked}
                    >
                      {t("delivery-confirm")}
                    </Button>
                  </>
                )}
                {delivery.state === DeliveryState.CONFIRMED && (
                  <>
                    <Button className="mx-1"
                      kind="danger"
                      onClick={handleConfirmedRollback}
                      disabled={isLoadingProgressConfirmed || isLoadingRollbackConfirmed}
                    >
                      {t("delivery-assign")}
                    </Button>
                    <Button className="mx-1"
                        kind="primary"
                        onClick={handleConfirmedProgress}
                        disabled={isLoadingProgressConfirmed || isLoadingRollbackConfirmed}
                    >
                      {t('delivery-accept')}
                    </Button>
                    </>
                )}
              </div>
            </div>
            <div className="w-2/5">
              <div className="flex justify-between mb-3">
                <span className="text-gray-500 ">{t("Code")}</span>
                <p> {delivery.code}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("delivery-date")}: </span>
                <p>{formatDate(delivery.scheduledFrom)}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("delivery-slot")}: </span>
                <p>{formatTimeSlot(delivery.scheduledFrom, delivery.scheduledTo)}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("State")}: </span>
                <p>{delivery.state}</p>
              </div>
            </div>
            <Text kind="story-title" className="text-2xl text-justify mt-8">
                {t("Beneficiary")}
              </Text>
            {!!beneficiary && 
            <div className="w-2/5">
            <BeneficiaryCard readOnly item={beneficiary} />
            </div>
            }
          </div>
          <div className="text-left mt-8">
            <div className="flex align-middle justify-between">
              <Text kind="story-title" className="text-2xl text-justify">
                {t("basket-list")}
              </Text>
            </div>
            {isLoadingBasketDeliveryList && <LoadingCenter />}
            {isErrorBasketDeliveryList && <div>{t("Error")}</div>}
            {!!basketDeliveryList && basketDeliveryList.count > 0 ? (
              <div className="flex-col">
                <DeliveryBasketTable
                  page={basketDeliveryList}
                  onChangePagination={setPagination}
                  onChangeSorting={setSorting}
                />
              </div>
            ) : (
              <BlockNotification
                hideCloseButton={false}
                kind="info"
                title={t("delivery-basket-missing-title")}
                subtitle={t("delivery-basket-missing-description")}
              />
            )}
          </div>
          {openConfirmModal && (
            <Modal
              open={true}
              onRequestSubmit={handleModalSubmit}
              onRequestClose={handleModalCancel}
              modalHeading={t("Confirm")}
              primaryButtonText={t("Confirm")}
              secondaryButtonText={t("cancel")}
              width="full"
              children={
                <>
                  {delivery.state === DeliveryState.BOOKED && (
                      <Text>
                      { t(action === Action.BOOKED_PROGRESS ? "delivery-confirm-confirm" : "delivery-assign-confirm") }
                    </Text>
                  )}
                  {delivery.state === DeliveryState.CONFIRMED && (
                      <Text>
                      { t(action === Action.CONFIRMED_PROGRESS ? "delivery-delivered-confirm" : "delivery-assign-confirm") }
                    </Text>
                  )}
                  {(isLoadingProgressBooked || isLoadingRollbackBooked) && <Loading withOverlay={false} small />}
                </>
              }
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DeliveryDetail;
