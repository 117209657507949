import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbHome,
  BreadcrumbItem,
  Button,
  Modal,
  Text,
} from "@wfp/react";
import { LoadingCenter } from "../commons/loading-center";
import { useNavigate, useParams } from "react-router-dom";
import { useGetBeneficiaryGroup } from "../../hooks/beneficiary-group";
import BeneficiaryGroupAddEdit from "./beneficiary-group-add-edit";
import { useState } from "react";
import { disableBeneficiaryGroup } from "../../services/beneficiary-group";

function BeneficiaryGroupDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: group,
    isLoading,
    isError,
  } = useGetBeneficiaryGroup(Number(id));
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteBeneficiaryGroup = () => {
    disableBeneficiaryGroup(Number(id)).then(() => {
      navigate("/beneficiary-group");
    })
  }
  return (
    <>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/#">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href="/beneficiary-group">
          {t("Groups")}
        </BreadcrumbItem>
        <BreadcrumbItem disableLink>{group?.name}</BreadcrumbItem>
      </Breadcrumb>
      {isLoading && <LoadingCenter />}
      {isError && <div>Error...</div>}
      {!!group && (
        <div className="mt-8">
          <div className="text-justify">
            <div className="flex justify-between align-middle">
              <Text kind="story-title" className="text-2xl mb-3 text-justify">
                {t("Details")}
              </Text>
              <div className="flex gap-2">
                <Button
                  kind="danger"
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                >
                  {t("delete")}
                </Button>
                <BeneficiaryGroupAddEdit item={group} />
              </div>
            </div>
            <div className="w-2/5">
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t('Name')}: </span>
                <p>{group.name}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t('Size')}: </span>
                <p>{group.count}</p>
              </div>
            </div>
          </div>
          {openDeleteModal && (
              <Modal
                  open={true}
                  onRequestSubmit={handleDeleteBeneficiaryGroup}
                  onRequestClose={() => setOpenDeleteModal(false)}
              modalHeading={t("delete")}
              primaryButtonText={t("Confirm")}
              secondaryButtonText={t("cancel")}
            >{t("AreYouSureToDelete?")}</Modal>
          )}
        </div>
      )}
    </>
  );
}

export default BeneficiaryGroupDetail;
