import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Text, useMediaQuery } from "@wfp/react";
import { PaginationParams } from '../../types/commons';
import { Role } from "../../types/user";
import { IDelivery } from "../../types/delivery";
import { useGetDeliveries } from "../../hooks/delivery";
import { useLoggedUser } from "../../hooks/user";
import { LoadingCenter } from '../commons/loading-center';
import { DeliveryCardDetail } from "./delivery-card-detail";
import DeliveryTable from './delivery-table';

function DeliveryPage() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [pagination, setPagination] = useState<PaginationParams>({ pageNumber: 1 });
  const [sorting, setSorting] = useState<string[] | undefined>(undefined);
  const { data: deliveryListPage, isLoading, isError } = useGetDeliveries(pagination, undefined, sorting);
  const [DeliveryAccepted, setDeliveryAccepted] = useState<
    IDelivery | undefined
  >();
  const [DeliveryRejected, setDeliveryRejected] = useState<
    IDelivery | undefined
  >();

  const user = useLoggedUser();

  function handleConfirmDelivery(delivery: IDelivery) {
    setDeliveryAccepted(undefined);
  }

  function handleRejectDelivery(delivery: IDelivery) {
    setDeliveryRejected(undefined);
  }

  return (
    <>
      <div className="mt-10 ">
        <div className="flex justify-between">
          <Text kind="story-title" className="text-2xl text-left">
            {t("Deliveries")}
          </Text>
        </div>
        {isError && <div>{t("Error")}</div>}
        {isLoading && <LoadingCenter />}
        {!!deliveryListPage && (
          <>
            {isSmallScreen ? (
              <>
                {deliveryListPage.results.map((delivery) => (
                  <DeliveryCardDetail
                    accept={setDeliveryAccepted}
                    reject={setDeliveryRejected}
                    item={delivery}
                    key={delivery.id}
                    readonly={user?.role !== Role.Beneficiary}
                  />
                ))}
              </>
            ) : (
                <DeliveryTable page={deliveryListPage}
                     onChangePagination={setPagination}
                     onChangeSorting={setSorting}
                    />
            )}
          </>
        )}
      </div>
      {!!DeliveryAccepted && (
        <>
          <Modal
            open={!!DeliveryAccepted}
            modalHeading={t("ConfirmDelivery")}
            primaryButtonText={t("Confirm")}
            secondaryButtonText={t("cancel")}
            onSecondarySubmit={() => setDeliveryAccepted(undefined)}
            onRequestClose={() => setDeliveryAccepted(undefined)}
            onRequestSubmit={() => handleConfirmDelivery(DeliveryAccepted)}
          >
            <div className="flex flex-col space-y-4 w-full">
              <div className="text-start">
                {t("DeliveryID")}: {DeliveryAccepted.id}
              </div>
              <div className="text-start">{t("DeliveryModalLabel")}</div>
            </div>
          </Modal>
        </>
      )}
      {!!DeliveryRejected && (
        <>
          <Modal
            open={!!DeliveryRejected}
            modalHeading={t("RejectDelivery")}
            primaryButtonText={t("Confirm")}
            secondaryButtonText={t("cancel")}
            onSecondarySubmit={() => setDeliveryRejected(undefined)}
            onRequestClose={() => setDeliveryRejected(undefined)}
            onRequestSubmit={() => handleRejectDelivery(DeliveryRejected)}
          >
            <div className="flex flex-col space-y-4 w-full">
              <div className="text-start">
                {t("DeliveryID")}: {DeliveryRejected.id}
              </div>
              <div className="text-start">{t("DeliveryRejectModalLabel")}</div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

export default DeliveryPage;
