export interface ListPage<T> {
    count: number;
    next: string | null;
    pageNumber: number;
    pageSize: number;
    previous: string | null;
    results: T[];
    filtering?: KVPair[];
    sorting?: string[];
}

export interface PaginationParams {
    pageNumber: number;
    pageSize?: number;
}

export enum SortMode {
    None = 0,
    Ascending = 1,
    Descending = 2,
}

export interface Sortable {
    code: string;
    field: string;
    label?: string;
    mode: SortMode;
    enabled: boolean;
}


export interface KVPair {
    [key: string]: string | string[];
}

export interface IServerError {
    status?: number;
    detail?: string[];
    errors?: string[];
}
