import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BlockNotification, Button, Modal, Text, useMediaQuery } from '@wfp/react';
import { KVPair, PaginationParams } from '../../types/commons';
import { IBasket } from "../../types/basket";
import { IBasketError, validateBasket } from '../../models/validate-basket';
import { saveBasket } from '../../services/basket';
import { useGetBaskets, useInvalidateBaskets } from '../../hooks/basket';
import { LoadingCenter } from '../commons/loading-center';
import BasketTable from "./basket-table";
import BasketForm from "./basket-form";

function BasketPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({ pageNumber: 1 });
  const [sorting, setSorting] = useState<string[] | undefined>(['id']);
  const [filters, setFilters] = useState<KVPair[] | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const { data: page, isLoading, isError } = useGetBaskets(paginationParams, filters, sorting);
  const { mutate: updateBasketsPage } = useInvalidateBaskets(paginationParams, filters, sorting);
  const [selectedItem, setSelectedItem] = useState<IBasket>();
  const [itemError, setItemError] = useState<IBasketError>();

  const handleBasketAdd = () => {
      if (!!page) {
          setOpenModal(true);
          setItemError(undefined);
          setSelectedItem({} as IBasket);
      }
  }

  const handleBasketSaveConfirm = () => {
      if (!!selectedItem) {
          validateBasket(selectedItem)
              .then((item) =>
                  saveBasket(item)
                      .then(res => {
                          setOpenModal(false);
                          setItemError(undefined);
                          setSelectedItem(res);
                          if (!selectedItem.id) {
                              navigate(`/basket/${res.id}`);
                          } else {
                              updateBasketsPage();
                          }
                      })
                      .catch(e => setItemError(e as IBasketError)))
              .catch(e => setItemError(e));
      }
  }

  const handleBasketSaveCancel = () => {
      setOpenModal(false);
      setItemError(undefined);
      setSelectedItem(undefined);
  }

  const handleBasketDelete = (basket: IBasket) => {
    setSelectedItem(basket);
    setItemError(undefined);
    setOpenModal(true);
  }

  const handleBasketEdit = (basket: IBasket) => {
    setSelectedItem(basket);
    setItemError(undefined);
    setOpenModal(true);
  }

  return (
    <>
      <div className="mt-10 ">
        <div className="flex justify-between">
          <Text kind="story-title" className="text-2xl text-left">
            {t("basket-list")}
          </Text>
          <Button onClick={handleBasketAdd}>{t("basket-add")}</Button>
        </div>
        {isLoading && <LoadingCenter />}
        {isError && <div>Error...</div>}
        {!!page && (
          <>
            {page.count === 0 && <BlockNotification
              kind="info"
              title={t("NoBaskets")}
              subtitle={t("NoBasketsDescription")}
            />}
            {isSmallScreen ? (
              <>
                {page.results.map((basket) => (
                  <div className="m-5 wfp--card-box p-4" key={basket.id} onClick={() => navigate(`/basket/${basket.id}`)}>
                    <div className="text-left m-4">
                      <div className="flex justify-between mb-3">
                        <span className="text-gray-500">{t("ID")}: </span>
                        <p>{basket.id}</p>
                      </div>
                      <div className="flex justify-between mb-3">
                        <span className="text-gray-500">{t("Name")}: </span>
                        <p>{basket.name}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="flex-col">
                  <BasketTable page={page}
                               onChangePagination={setPaginationParams}
                               onChangeSorting={setSorting}
                               onDeleteRow={handleBasketDelete}
                               onEditRow={handleBasketEdit}
                  />
              </div>
            )}
          </>
        )}
      </div>
      {openModal && !selectedItem?.id && (
      <Modal
        open={true}
        onRequestClose={handleBasketSaveCancel}
        onRequestSubmit={handleBasketSaveConfirm}
        modalHeading={t("basket-add")}
        primaryButtonText={t("add")}
        secondaryButtonText={t("cancel")}
      >
        <BasketForm onChange={setSelectedItem} item={selectedItem!} error={itemError} />
      </Modal>
      )}
      {openModal && !!selectedItem?.id && (
      <Modal
        open={true}
        onRequestClose={handleBasketSaveCancel}
        onRequestSubmit={handleBasketSaveConfirm}
        modalHeading={t("basket-edit")}
        primaryButtonText={t("save")}
        secondaryButtonText={t("cancel")}
      >
        <BasketForm onChange={setSelectedItem} item={selectedItem!} error={itemError} />
      </Modal>
      )}
    </>
  );
}

export default BasketPage;
