export interface IDelivery {
  beneficiary: string;
  code: string;
  beneficiaryEmail?: string;
  cycle: number;
  createdOn: string;
  cycleName: string;
  dataStarted: string;
  id: number;
  baskets: number[];
  state: string;
  scheduledFrom: string;
  scheduledTo: string;
}

export enum DeliveryState {
  ASSIGNED = "ASSIGNED",
  BOOKED = "BOOKED",
  CONFIRMED = "CONFIRMED",
  DONE = "DONE",
}

export interface ISlot {
  id: number;
  name: string;
  scheduleFrom: number;
  scheduleTo: number;
}
