import React, { createContext, useState, useCallback, useMemo } from 'react';
import { ToastNotification } from '@wfp/react';

interface INotificationContextProps {
  show: (title: string, subTitle: string, options: INotificationOptions) => void;
  hide: VoidFunction;
}

const defaultContext: INotificationContextProps = {
  show: () => {},
  hide: () => {}
};

type Kinds = 'success' | 'error' | 'info' | 'warning' | 'warning-alt';

export const NotificationContext = createContext<INotificationContextProps>(defaultContext);

interface INotificationOptions {
  kind: Kinds;
  timeout?: number;
}
const defaultOptions: INotificationOptions = {
  kind: 'success'
};

const NotificationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isNotificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [subTitle, setSubTitle] = useState<string>('');
  const [options, setOptions] = useState<INotificationOptions>(defaultOptions);

  const show = useCallback((_title: string, _subTitle: string, _options: INotificationOptions) => {
    setTitle(_title);
    setSubTitle(_subTitle);
    setOptions(_options);
    setNotificationVisible(true);
  }, []);

  const hide = useCallback(() => {
    setNotificationVisible(false);
  }, []);

  const contextValue = useMemo(() => ({ show, hide }), [show, hide]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {isNotificationVisible && (
        <div className='toast_container'>
          <ToastNotification
            title={title}
            subtitle={subTitle}
            notificationType='toast'
            onCloseButtonClick={hide}
            onClose={hide}
            {...options}
          />
        </div>
      )}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
