import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { IDelivery } from '../types/delivery';
import { KVPair, ListPage, PaginationParams } from '../types/commons';
import {
    rollbackBookedDelivery,
    confirmDelivery,
    getDeliveries,
    getDeliveryDetail,
    deliverDelivery, rollbackConfirmedDelivery
} from '../services/delivery';
import { IBasketDelivery } from '../types/basket';
import { getBasketDeliveryList } from '../services/basket';

export function useGetDeliveries(params: PaginationParams, filters?: KVPair[], sorting?: string[]): UseQueryResult<ListPage<IDelivery>> {
    return useQuery(['delivery', 'list'], () => getDeliveries(params, filters, sorting), {
        retry: false
    });
}

export function useInvalidateDeliveries() {
    const queryClient = useQueryClient();
    return useMutation(() => Promise.resolve(), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['delivery', 'list']})
    });
}

export function useGetDelivery(id: number): UseQueryResult<IDelivery> {
    return useQuery(['delivery', id], () => getDeliveryDetail(id), {
        retry: false
    });
}

export function useInvalidateDelivery(
  id: number,
) {
    const queryClient = useQueryClient();
    return useMutation(() => Promise.resolve(), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['delivery', id],})
    });
}

export function useGetCycleDeliveries(id: number, params: PaginationParams = { pageNumber: 1 }, sorting?: string[], filters?: KVPair[]): UseQueryResult<ListPage<IDelivery>> {
    const filter = {key: 'cycle', value: id.toString()};
    filters = !!filters ? filters.concat([filter]) : [filter];
    return useQuery(['delivery-list', id], () => getDeliveries(params, filters, sorting), {
        retry: false
    });
}

export function useInvalidateCycleDeliveries(
  id: number,
) {
    const queryClient = useQueryClient();
    return useMutation(() => Promise.resolve(), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['delivery-list', id]})
    });
}

export function useRollbackConfirmedDelivery(id: number) {
    const queryClient = useQueryClient();
    return useMutation(() => rollbackConfirmedDelivery(id), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['delivery', id]})
    });
}

export function useProgressConfirmedDelivery(id: number){
    const queryClient = useQueryClient();
    return useMutation(() => deliverDelivery(id), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['delivery', id]})
    });
}

export function useRollbackBookedDelivery(id: number) {
    const queryClient = useQueryClient();
    return useMutation(() => rollbackBookedDelivery(id), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['delivery', id]})
    });
}

export function useProgressBookedDelivery(id: number) {
    const queryClient = useQueryClient();
    return useMutation(() => confirmDelivery(id), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['delivery', id]})
    });
}

export function useGetBasketDeliveryList(
  deliveryId: number,
  enabled: boolean,
  params: PaginationParams,
  filters?: KVPair[],
  sorting?: string[],
): UseQueryResult<ListPage<IBasketDelivery>> {
    const filter = {key: 'delivery', value: deliveryId.toString()};
    filters = !!filters ? filters.concat([filter]) : [filter];
    return useQuery(
    ["basket-delivery-list", deliveryId],
    () => getBasketDeliveryList(params, filters, sorting),
    {
      retry: false,
      enabled: enabled,
    }
  );
}

export function useInvalidateBasketDeliveryList(
  deliveryId: number,
) {
    const queryClient = useQueryClient();
    return useMutation(() => Promise.resolve(), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["basket-delivery-list", deliveryId],})
    });
}
