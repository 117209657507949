import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { IBeneficiaryGroup } from "../types/beneficiary";
import { KVPair, ListPage, PaginationParams } from '../types/commons';
import { getBeneficiaryGroups, getBeneficiaryGroupDetail } from "../services/beneficiary-group";


export function useGetBeneficiaryGroups(params: PaginationParams, filters?: KVPair[], sorting?: string[]): UseQueryResult<
  ListPage<IBeneficiaryGroup>
> {
  return useQuery(
    ["beneficiary-group-list", params, filters, sorting],
    () => getBeneficiaryGroups(params, filters, sorting),
    {
      retry: false,
    }
  );
}

export function useGetBeneficiaryGroup(id: number): UseQueryResult<IBeneficiaryGroup> {
  return useQuery(["beneficiary-group", id], () => getBeneficiaryGroupDetail(id), {
    retry: false,
  });
}

export function useInvalidateBeneficiaryGroups(
  params: PaginationParams,
  filters?: KVPair[],
  sorting?: string[],
) {
  const queryClient = useQueryClient();
  return useMutation(() => Promise.resolve(), {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["beneficiary-group", "list", params, filters, sorting] }),
  });
}

export function useInvalidateBeneficiaryGroup(id: number) {
  const queryClient = useQueryClient();
  return useMutation(() => Promise.resolve(), {
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["beneficiary-group", id] }),
  });
}
