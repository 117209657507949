import {Component, HTMLAttributes} from "react";
import AsyncSelect from "react-select/async";
import {GetOptionLabel, GetOptionValue} from "react-select";
import {Text} from "@wfp/react";

export interface TypeaheadProps<T> extends HTMLAttributes<T> {
    className?: string;
    getOptionLabel?: GetOptionLabel<T>; // override default in case 'name' is not the label
    getOptionValue?: GetOptionValue<T>; // override default in case 'id' is not the value
    invalid?: boolean;
    invalidText?: string;
    isDisabled?: boolean;
    label?: string | null;
    noOptionsMessage?: string;
    onChange?: (e: any) => void;
    onClick?: () => void;
    onSelectOption?: (o: {id: string, name: string }) => void;
    options?: T[];
    placeholder?: string;
    required?: boolean;
    search: (value: string) => Promise<T[]>;
    value?: T;
}

export class Typeahead<T> extends Component<TypeaheadProps<T>, {}> {
    options: T[];

    timer: NodeJS.Timeout | null;

    constructor(props: TypeaheadProps<T>) {
        super(props);
        this.state = {};
        this.options = props.options || [];
        this.timer = null;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        if (!!this.timer) {
            clearTimeout(this.timer);
        }
    }

    handleSearch(self: Typeahead<T>, inputValue?: string): Promise<T[]> {
        if (!!self.timer) {
            clearTimeout(self.timer);
        }
        if (!!inputValue && inputValue.length < 3) {
            return Promise.resolve(self.options);
        }
        return new Promise<T[]>((resolve) => {
            self.timer = setTimeout(() => {
                return self.props.search(inputValue || '').then((results) => {
                    self.options = results;
                    resolve(results);
                });
            }, 300);
        });
    }

    handleKeyDown(self: Typeahead<T>, e: any): void {
        if (e.charCode === "13" && !!this.props.onClick) {
            this.props.onClick();
        }
    }

    handleChange(self: Typeahead<T>, e: any): void {
        if (!!self.props.onChange) {
            self.props.onChange({target: {value: self.handleOptionValue(self, e)}});
        }
        if (!!self.props.onSelectOption) {
            self.props.onSelectOption({
                id: self.handleOptionValue(self, e),
                name: self.handleOptionLabel(self, e)
            });
        }
    }

    handleOptionLabel(self: Typeahead<T>, option: T): string {
        return !!self.props.getOptionLabel ? self.props.getOptionLabel(option) : !!option ? (option as any).name || '' : '';
    }

    handleOptionValue(self: Typeahead<T>, option: T): string {
        return !!self.props.getOptionValue ? self.props.getOptionValue(option) : !!option ? (option as any).id?.toString() || '' : '';
    }

    render() {
        return <div className={this.props.className || ''}>
            {!!this.props.label && (
            <label className="wfp--label" htmlFor="searchAhead">{this.props.label} {this.props.required ? '*' : ''}</label>
            )}
            <AsyncSelect name="searchAhead text-start"
                         styles={{
                             // @ts-ignore
                             control: (base, props) => ({
                                 ...base,
                                 border: this.props.invalid ? '1px solid red' : '1px solid #77a0b6',
                             })
                         }}
                         isDisabled={this.props.isDisabled}
                         onKeyDown={(e) => this.handleKeyDown(this, e)}
                         escapeClearsValue
                         isClearable
                         noOptionsMessage={() => this.props.noOptionsMessage || 'no_option_value'}
                         value={this.props.value}
                         getOptionLabel={(o) => this.handleOptionLabel(this, o)}
                         getOptionValue={(o) => this.handleOptionValue(this, o)}
                         onChange={(e) => this.handleChange(this, e)}
                         loadOptions={(e) => this.handleSearch(this, e)}
                         placeholder={this.props.placeholder || 'search'}
            />
            {!!this.props.invalidText && (
                <Text className="text-red-500" kind="error">
                    {this.props.invalidText}
                </Text>
            )}
        </div>
    }
}
