import { useTranslation } from "react-i18next";
import { BlockNotification, Form, RadioButton, Text } from "@wfp/react";
import { useGetBaskets } from "../../hooks/basket";
import Checkbox from "../commons/Checkbox";
import { IBulkError } from "./delivery-add";
import { LoadingCenter } from '../commons/loading-center';
import { Typeahead } from '../commons/Typeahead';
import { IBeneficiary, IBeneficiaryGroup } from '../../types/beneficiary';
import { getBeneficiaryGroups } from '../../services/beneficiary-group';
import { useGetBeneficiaryGroups } from '../../hooks/beneficiary-group';
import { getBeneficiaries } from '../../services/beneficiary';
import useGetBeneficiaries from '../../hooks/beneficiary';
import { useState } from 'react';

interface Props {
  onChangeBaskets: (baskets: number[]) => void;
  onChangeBeneficiary: (b: IBeneficiary | undefined) => void;
  onChangeGroup: (g: IBeneficiaryGroup | undefined) => void;
  onChangeAllBeneficiary: (v: boolean) => void;
  baskets?: number[];
  allBeneficiaries: boolean;
  beneficiary?: IBeneficiary;
  group?: IBeneficiaryGroup;
  error?: IBulkError;
}

function DeliveryBulkForm(props: Props) {
  const { t } = useTranslation();
  const { data: basketsPage, isLoading: isLoadingBaskets, isError: isErrorBaskets } = useGetBaskets({ pageNumber: 1 });
  const { data: groupsPage } = useGetBeneficiaryGroups({pageNumber: 1, pageSize: 5});
  const { data: beneficiariesPage } = useGetBeneficiaries({pageNumber: 1, pageSize: 5});
  const [selected, setSelected] = useState<number>();

  const onHandleBasket = (id: number) => {
    if (props.baskets?.includes(id)) {
      props.onChangeBaskets(props.baskets?.filter((b) => b !== id));
    } else {
      props.onChangeBaskets(!!props.baskets ? [...props.baskets, id] : [id]);
    }
  };

  const handleSelectAll = (e: any) => {
      setSelected(0);
      props.onChangeAllBeneficiary(true);
  }

  const handleSelectGroup = (e: any) => {
      setSelected(1);
      props.onChangeAllBeneficiary(false);
      props.onChangeBeneficiary(undefined);
  }

  const handleSelectBeneficiary = (e: any) => {
      setSelected(2);
      props.onChangeAllBeneficiary(false);
      props.onChangeGroup(undefined);
  }

  return (
    <Form>
      {props.error?.errors && (
        <BlockNotification
          kind="error"
          title={t("Error")}
          subtitle={t(props.error?.errors?.toString())}
        />
      )}
      <div className="flex-row">
        {isLoadingBaskets && <LoadingCenter />}
        {isErrorBaskets && <div>Error</div>}
        {!!basketsPage && (
          <div className="wfp--form-item">
            <div className="wfp--label"><Text>{t("basket-list")}</Text></div>
            {props.error?.baskets && (
              <BlockNotification
                kind="error"
                title={t("Error")}
                subtitle={t(props.error?.baskets?.toString())}
              />
            )}
            <div className="overflow-y-scroll max-h-60 flex flex-wrap">
              {basketsPage.count === 0 && <BlockNotification
              kind="info"
              title={t("basket-missing-title")}
              subtitle={t("basket-missing-description")}
            />}
              {basketsPage.results.map((b, idx) => (
                <div key={idx} className="mr-1">
                  <Checkbox
                    name={b.name}
                    value={b.id}
                    onChange={(e) => onHandleBasket(e.target.value)}
                    labelText={b.name}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
          <div className="wfp--form-item">
              <div className="wfp--label"><Text>{t("beneficiary-radio-choose")}</Text></div>
              {props.error?.beneficiaries && (
                  <BlockNotification
                      kind="error"
                      title={t("Error")}
                      subtitle={t(props.error?.beneficiaries?.toString())}
                  />
              )}

              <div className="flex p-2">
                  <RadioButton
                      onChange={handleSelectAll}
                      className="my-1"
                      name="mode"
                      value="a"
                      labelText={t('AllBeneficiaries')}
                      defaultChecked={props.allBeneficiaries}
                  />
              </div>
              <div className="flex justify-between p-2">
                  <RadioButton
                      onChange={handleSelectGroup}
                      className="my-1"
                      name="mode"
                      value="g"
                      labelText={t('Group')}
                      defaultChecked={!!props.group}
                  />
                  <div className="mx-9 text-left">
                      <Typeahead<IBeneficiaryGroup>
                          isDisabled={selected !== 1}
                          invalid={!!props.error?.group && selected === 1}
                          invalidText={selected === 1 ? t(props.error?.group?.toString() || '') : ''}
                          label=''
                          placeholder={t('beneficiary-group-search') as string}
                          noOptionsMessage={t('search-option-none') as string}
                          options={groupsPage?.results}
                          onSelectOption={(v) => props.onChangeGroup({id: Number(v.id), name: v.name} as IBeneficiaryGroup)}
                          search={(e) => getBeneficiaryGroups({pageNumber: 1, pageSize: 5}, [{
                              key: 'name_ic',
                              value: e
                          }]).then(res => res.results)}
                          value={props.group}
                      >
                      </Typeahead>
                  </div>
              </div>
              <div className="flex justify-between p-2">
                  <RadioButton
                      onChange={handleSelectBeneficiary}
                      className="my-1"
                      name="mode"
                      value="b"
                      labelText={t('beneficiary-label')}
                      defaultChecked={!!props.beneficiary}
                  />
                  <div className="mx-6 text-left">
                      <Typeahead<IBeneficiary>
                          isDisabled={selected !== 2}
                          getOptionLabel={(b) => !!b ? b.email : ''}
                          invalid={!!props.error?.beneficiary && selected === 2}
                          invalidText={selected === 2 ? t(props.error?.beneficiary?.toString() || '') : ''}
                          label=''
                          placeholder={t('beneficiary-search') as string}
                          noOptionsMessage={t('search-option-none') as string}
                          options={beneficiariesPage?.results}
                          onSelectOption={(v) => props.onChangeBeneficiary({id: Number(v.id), email: v.name} as IBeneficiary)}
                          search={(e) => getBeneficiaries({pageNumber: 1, pageSize: 5}, [{
                              key: "email_ic",
                              value: e
                          }]).then(res => res.results)}
                          value={props.beneficiary}
                      >
                      </Typeahead>
                  </div>
              </div>
          </div>
      </div>
    </Form>
  );
}

export default DeliveryBulkForm;
